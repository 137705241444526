import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";

import "mapbox-gl/dist/mapbox-gl.css";
import "./styles/index.scss";

import { ErrorBoundary } from "react-error-boundary";
import App from "./App";
import { AppContextProvider } from "./contexts/app/app-context-provider";

import './i18n';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ErrorBoundary
    FallbackComponent={({ error }) => {
      console.log(error);

      return <></>;
    }}
  >
    <React.StrictMode>
      <Suspense fallback="Loading">
        <AppContextProvider>
            <App />
        </AppContextProvider>
      </Suspense>
    </React.StrictMode>
  </ErrorBoundary>
);
