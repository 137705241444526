import { Button, Card } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { Grant } from '../../../api';
import CardTitle from '../../atoms/card/card-title';
import './grant-list-card.scss';

type GrantListCardOptions = {
  className?: string;
  grant: Grant;
};

export const GrantListCard = ({ className = '', grant }: GrantListCardOptions) => {
  const { t } = useTranslation();

  console.log(grant.fileUrl);

  return (
    <Card className="w-full m-0 h-fit card tool-list-card">
      <div className="flex flex-row flex-wrap p-5">
        <div className="flex flex-col justify-center w-full mt-4 body-container lg:mt-3">
          <div className="flex flex-col w-full mb-2 title lg:flex-col-reverse">
            <CardTitle className={`leading-6`} text={grant.title ?? ''}></CardTitle>
          </div>
          <div className="flex flex-col w-full lg:mb-auto lg:flex-grow">
            <div className="w-full mx-auto mt-4 button">
              <Button
                as="a"
                download
                href={grant.fileUrl}
                target="_blank"
                pill
                color="primary"
                className="w-full h-10 text-base font-semibold"
              >
                {t('grants.download')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
