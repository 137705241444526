'use client';

import { Button, Navbar } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavbarDropdownLink } from '../../atoms/navbar-link-group/navbar-dropdown-link';
import { NavbarLink } from '../../atoms/navbar-link/navbar-link';
import './navbar.scss';
import { isSafari } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { LanguagePicker } from '../../molecules/language-picker/language-picker';

export const CustomNavbar = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { pathname } = useLocation();

  const { t } = useTranslation(['translation', 'routes']);

  const handleClick = () => {
    setModalOpen((current) => !current);
  };

  useEffect(() => {
    if (pathname.includes('sobre-nos')) {
      document.getElementById('dropdown-hover')?.classList.add('active');
    } else {
      document.getElementById('dropdown-hover')?.classList.remove('active');
    }

    setModalOpen(false);
  }, [pathname]);

  return (
    <>
      <Navbar
        id="nav"
        className={`${isSafari ? 'relative' : 'fixed'} z-50 justify-end w-full px-0 py-0 shadow-md h-navbar sm:px-0 lg:px-2`}
        fluid
        style={{ padding: modalOpen ? '0 0' : '' }}
      >
        <Link className="flex items-center" to="/">
          <img src="/assets/images/logos/logo_mypolis.png" className="mx-3 h-7 lg:h-8" alt="MyPolis Logo" />
        </Link>
        <div className="relative flex items-center h-full ml-auto mr-3">
          <ul
            id="nav-menu"
            style={{
              left: modalOpen ? 0 : '',
            }}
            className={`overflow-hidden mt-15 top-0 left-full fixed w-screen bg-white z-40 flex flex-col justify-start items-center h-full navbar-link-container lg:static lg:flex-row lg:overflow-auto lg:w-auto lg:items-center lg:justify-normal lg:mt-0 lg:max-h-full`}
          >
            <NavbarLink
              modalOpen={modalOpen}
              linkClasses="mt-2 lg:hidden whitespace-nowrap"
              to={t('routes.main', { ns: 'routes' })}
            >
              <img className="w-6 h-6 mb-1 mr-1" src="/assets/images/other/smol-home.png" alt="Small House" />
              {t('common.mainPage')}
            </NavbarLink>
            <NavbarLink
              modalOpen={modalOpen}
              linkClasses="whitespace-nowrap"
              to={t('routes.localAuthorities', { ns: 'routes' })}
            >
              {t('common.localAuthorities')}
            </NavbarLink>
            <NavbarLink modalOpen={modalOpen} to={t('routes.schools', { ns: 'routes' })}>
              {t('common.schools')}
            </NavbarLink>
            <NavbarLink modalOpen={modalOpen} linkClasses="lg:hidden" to={t('routes.htp', { ns: 'routes' })}>
              {t('common.htp')}
            </NavbarLink>
            <NavbarDropdownLink containerClasses="min-w-[150px]" dropDownText={t('common.aboutUs')}>
              <NavbarLink
                modalOpen={modalOpen}
                containerClasses="font-normal"
                linkClasses="h-navbar min-w-[150px]"
                to={t('routes.history', { ns: 'routes' })}
              >
                {t('common.history')}
              </NavbarLink>

              <NavbarLink
                modalOpen={modalOpen}
                containerClasses="font-normal"
                linkClasses="h-navbar min-w-[150px]"
                to={t('routes.team', { ns: 'routes' })}
              >
                {t('common.team')}
              </NavbarLink>

              <NavbarLink
                modalOpen={modalOpen}
                containerClasses="font-normal"
                linkClasses="h-navbar min-w-[150px]"
                to={t('routes.tools', { ns: 'routes' })}
              >
                {t('common.tools')}
              </NavbarLink>

              <NavbarLink
                modalOpen={modalOpen}
                containerClasses="font-normal"
                linkClasses="h-navbar min-w-[150px]"
                to={t('routes.impact', { ns: 'routes' })}
              >
                {t('common.impact')}
              </NavbarLink>
              {/* <NavbarLink
                modalOpen={modalOpen}
                containerClasses="font-normal"
                linkClasses="h-navbar min-w-[150px]"
                to={t("routes.jobs", {ns: "routes"})}
              >
                {t("common.jobs")}
              </NavbarLink>
              <NavbarLink
                modalOpen={modalOpen}
                containerClasses="font-normal"
                linkClasses="h-navbar min-w-[150px]"
                to={t("routes.news", {ns: "routes"})}
              >
                {t("common.news")}
              </NavbarLink> */}
            </NavbarDropdownLink>
            <NavbarLink
              modalOpen={modalOpen}
              linkClasses="hidden lg:block whitespace-nowrap"
              to={t('routes.htp', { ns: 'routes' })}
            >
              {t('common.htp')}
            </NavbarLink>
            <NavbarLink
              modalOpen={modalOpen}
              to="https://community.mypolis.eu/login"
              target="_blank"
              disabled={true}
              linkClasses="hidden lg:block"
            >
              {t('common.login')}
            </NavbarLink>
            <li className="flex items-center lg:hidden">
              <LanguagePicker />
            </li>
          </ul>

          <Button
            pill
            href="https://community.mypolis.eu/register"
            target="_blank"
            color="primary"
            size="navbar"
            className="m-auto ml-auto font-bold lg:ml-5 whitespace-nowrap"
          >
            {t('common.createAcct')}
          </Button>

          <div
            className={`hamburger-menu gap-y-[6px] ${
              modalOpen ? 'open' : ''
            } flex flex-col overflow-hidden justify-center items-center rounded-full w-9 h-9 ml-3 shadow-hamburger cursor-pointer border-primary-200 border-solid z-[60] lg:hidden`}
            onClick={handleClick}
          >
            <div
              className={`w-1/2 border-t-1 border-secondary-950 ${modalOpen ? '-rotate-45 translate-y-[7px]' : ''}`}
            ></div>
            <div className={`w-1/2 border-t-1 border-secondary-950 ${modalOpen ? 'opacity-0' : 'opacity-1'}`}></div>
            <div
              className={`w-1/2 border-t-1 border-secondary-950 ${modalOpen ? 'rotate-45 -translate-y-[7px]' : ''}`}
            ></div>
          </div>
        </div>
      </Navbar>
    </>
  );
};
