import { Button, Card } from 'flowbite-react';
import CardSubtitle from '../../atoms/card/card-subtitle';
import CardTitle from '../../atoms/card/card-title';
import './tool-card.scss';
import { Tool } from '../../../api';
import { useTranslation } from 'react-i18next';

type ToolCardOptions = {
  className?: string;
  showTargets?: boolean;
  tool: Tool;
  showMoreClickHandler: React.Dispatch<any>;
  activeToolHandler: React.Dispatch<any>;
};

export const ToolCard = ({
  className = '',
  showTargets = false,
  tool,
  showMoreClickHandler = () => {},
  activeToolHandler = () => {},
}: ToolCardOptions) => {
  const {t} = useTranslation()

  return (
    <Card className="w-64 h-[430px] card tool-card">
      <div className="flex flex-col w-full h-full p-5">
        <div
          className="w-full h-auto image grow rounded-card border-1"
          style={{ background: `url(${tool?.banner})` }}
        ></div>
        <div className="flex flex-col justify-center w-full mt-4 body-container">
          {showTargets &&
            tool.technicalSheet &&
            tool.technicalSheet.target &&
            tool.technicalSheet.target.length > 1 && (
              <div className="flex flex-row flex-wrap items-start justify-start mb-1 targets gap-x-1 gap-y-2">
                {tool.technicalSheet.target.map((target) => (
                  <span className="px-3 py-1 text-xs font-light border-solid rounded-full w-fit border-secondary-900/40 border-1">
                    {target}
                  </span>
                ))}
              </div>
            )}
          <div className="w-full mb-2 title">
            <CardTitle className="leading-6" text={tool.title ?? ''}></CardTitle>
          </div>
          <div className="flex flex-col w-full">
            <div className="w-full">
              <CardSubtitle text={tool.description ?? ''}></CardSubtitle>
            </div>
            <div className="w-full mx-auto mt-4 button">
              <Button
                pill
                color="primary"
                className="w-full h-10 text-base font-semibold"
                onClick={() => {
                  activeToolHandler(tool);
                  showMoreClickHandler(true);
                }}
              >
                {t("common.moreInfo")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
