import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export type Language = {
  id: string;
  label: string;
  enabled: boolean;
};

export const Languages: Language[] = [
  {
    id: 'pt',
    label: 'Português',
    enabled: true,
  },
  {
    id: 'en',
    label: 'English',
    enabled: true,
  },
  {
    id: 'es',
    label: 'Español',
    enabled: false,
  },
  {
    id: 'pl',
    label: 'Polski',
    enabled: false,
  },
  {
    id: 'it',
    label: 'Italiano',
    enabled: false,
  },
  {
    id: 'nl',
    label: 'Nederlands',
    enabled: false,
  },
  {
    id: 'hu',
    label: 'Magyar',
    enabled: false,
  },
  {
    id: 'fr',
    label: 'Français',
    enabled: false,
  },
];

export const LanguagesCurrentlyAvailable = Languages.filter((l) => l.enabled);

i18next
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'pt',
    backend: {
      backends: [HttpBackend, HttpBackend],
      backendOptions: [
        {
          loadPath: `${process.env.REACT_APP_LOCALIZATION_URL}/locales/{{lng}}/{{ns}}.json`,
          expirationTime: 0,
          requestOptions: {
            cache: 'no-cache',
          },
        },
        {
          loadPath: `/locales/{{lng}}/{{ns}}.json`,
        },
      ],
    },
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    detection: {
      order: ['path', 'localStorage', 'navigator'],
      lookupLocalStorage: 'currentLang',
      lookupFromPathIndex: 0,
      caches: ['localStorage'],
    },
    initImmediate: true,
    supportedLngs: LanguagesCurrentlyAvailable.map((l) => l.id),
    returnNull: true,
  });
export default i18next;
