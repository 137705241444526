import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImplementedProposal } from '../../../api';
import { ImpactDisplayer } from '../../molecules/impact-displayer/impact-displayer';
import { ProposalCard } from '../../molecules/proposal-card/proposal-card';
import { ProposalModal } from '../../molecules/proposal-modal/proposal-modal';

export const ImplementedProposals = () => {
  const [openModal, setOpenModal] = useState(false);
  const [activeProposal, setActiveProposal] = useState<Required<ImplementedProposal>>();

  const { t } = useTranslation(['translation', 'implemented-proposals']);

  const metrics: {
    highlight: string;
    text: string;
    image: string;
  }[] = t('landing.implementedProposals.metrics', { returnObjects: true })

  const implementedProposals: ImplementedProposal[] = t('implementedProposals', { ns: 'implemented-proposals', returnObjects: true })

  return (
    <div className="flex flex-col items-center mt-28">
      <div className="flex flex-col items-center gap-y-6">
        <span className="m-auto text-2.5xl font-semibold font-title text-center">
          {t('landing.implementedProposals.title', { ns: 'translation' })}
        </span>
        <div className="relative flex flex-row">
          <img src="/assets/images/lines/spiral.png" alt="" className="absolute -left-36 w-32 -top-16 rotate-[36deg]" />
          <img src="/assets/images/other/white_star.svg" alt="" className="absolute w-14 left-10 -top-[152px]" />
          <img src="/assets/images/other/white_star.svg" alt="" className="absolute w-9 -right-[106px] -top-10" />
          <span className="px-6 m-auto text-lg text-center font-body lg:px-0">
            {t('landing.implementedProposals.subTitle', { ns: 'translation' })}
          </span>
        </div>
        <div className="flex flex-wrap justify-center">
          {metrics && typeof metrics === "object" && metrics.map((metric, i) => (
            <ImpactDisplayer
              key={i}
              imgSrc={metric.image && metric.image !== '' ? metric.image : '/assets/images/impact/impact_1.svg'}
              title={metric.highlight}
              description={metric.text}
            ></ImpactDisplayer>
          ))}
        </div>

        <span className="px-6 m-auto text-lg text-center font-body lg:px-0 mt-13 -mb-7">
          {t('introText', { ns: 'implemented-proposals' })}
        </span>

        <div
          className="flex w-[100vw] mt-8 px-3 pb-3 scrollbar-thumb-rounded-full scrollbar-track-rounded-full
                    scrollbar-thumb-bg-700/40 scrollbar-track-transparent scrollbar-thin
                    overflow-x-auto"
        >
          <div className="relative flex pr-8 mx-auto left-4">
            {implementedProposals && typeof implementedProposals === "object" && implementedProposals.map((el, i) => {
              return (
                <ProposalCard
                  key={`${el.id}`}
                  proposal={el}
                  activeProposal={activeProposal}
                  activeProposalHandler={setActiveProposal}
                  showMoreClickHandler={setOpenModal}
                />
              );
            })}

            <ProposalModal
              modalCloseHandler={setOpenModal}
              openModal={openModal}
              proposal={activeProposal as Required<ImplementedProposal>}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
