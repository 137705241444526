import { useTranslation } from "react-i18next";

export const Backoffice = () => {

  const { t } = useTranslation()

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center gap-y-6">
        <span className="m-auto mt-24 text-2.5xl font-semibold font-title relative">
          {t("backoffice.title")}
          <img src="/assets/images/lines/hard_angles.png" alt="Lines" className="absolute -top-12 -right-24" />
        </span>
        <div className="flex flex-col-reverse lg:flex-col">
          <div className="flex flex-row">
            <span className="px-8 m-auto text-lg text-center font-body lg:px-0">
              {t("backoffice.subTitle1")}&nbsp;
              <br className="hidden lg:block" />{t("backoffice.subTitle2")}
            </span>
          </div>
          <div className="flex mx-auto my-6">
            <img
              src="/assets/images/mocks/mock_bo.png"
              alt="Backoffice"
              className="max-w-full lg:max-w-[600px] p-4 drop-shadow-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
