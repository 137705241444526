export const SuccessToast = () => (
  <div
    className={`flex flex-col justify-center text-center items-center relative max-w-md w-[552px] h-[320px] bg-white shadow-card rounded-card pointer-events-auto ring-1 ring-black ring-opacity-5`}
  >
    <img
      className="absolute w-28 top-8 -left-2 -rotate-[25deg] lg:w-38"
      src="/assets/images/other/rocketship.png"
      alt="Foguetão"
    />
    <div className="font-body text-primary-500 font-normal text-lg">Mensagem enviada.</div>
    <div className="font-title text-secondary-500 font-normal text-2.5xl">
      Obrigado pela
      <br />
      mensagem!
    </div>
    <div className="font-body text-secondary-500 font-normal text-lg mt-5">
      Iremos responder com a maior <br />
      brevidade possível
    </div>
  </div>
);
