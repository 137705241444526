import { Children, PropsWithChildren, useState } from "react";
import "./navbar-dropdown-link.scss";

type NavbarDropdownLinkProps = {
  href?: string;
  containerClasses?: string;
  dropDownText?: string;
};

export const NavbarDropdownLink = ({
  href = "#",
  containerClasses = "",
  dropDownText = "",
  children
}: PropsWithChildren<NavbarDropdownLinkProps>) => {
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  const maxHeight = Children.count(children) * 60;

  return (
    <li
      id="dropdown-hover"
      className={`flex flex-col items-center justify-center w-auto min-h-[60px]
      lg:hover:bg-gray-100 lg:active:bg-gray-200 lg:font-normal lg:justify-center cursor-pointer
      ${containerClasses} ${expanded ? "expand" : ""}`}
    >
      <div
        id="label"
        className="flex items-center justify-center min-h-[60px]"
        onClick={handleClick}
      >
        <span className="font-medium lg:font-normal">{dropDownText}</span>
        <svg
          className={`ml-2 lg:hidden`}
          width="18"
          height="10"
          viewBox="0 0 18 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 8.66406L17.0623 0.601805"
            stroke="black"
            strokeLinecap="round"
          />
          <path
            d="M9.06226 8.66406L0.999998 0.601805"
            stroke="black"
            strokeLinecap="round"
          />
        </svg>
      </div>
      <ul
        id="dropdown-item-container"
        style={{
          maxHeight: expanded ? maxHeight : "",
          opacity: expanded ? 100 : "",
        }}
        className={`max-h-0 opacity-0 overflow-hidden bg-white lg:hidden lg:max-h-fit lg:opacity-100`}
      >
        {children}
      </ul>
    </li>
  );
};
