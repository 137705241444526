import { LanguagesCurrentlyAvailable as Languages } from '../../../i18n';

type LanguageButtonFooterProps = {
  lang: string;
  openDropdown: boolean;
  setOpenDropdown: Function;
  selectLanguageHandler: Function;
};
export const LanguageButtonFooter = ({
  lang,
  openDropdown,
  setOpenDropdown,
  selectLanguageHandler,
}: LanguageButtonFooterProps) => {
  return (
    <div className="relative flex flex-col w-[210px] mt-6 mb-20 h-13 lg:mt-11 lg:mb-12">
      {lang && (
        <ul
          id="lang-selector"
          className={`w-[210px] absolute bg-white shadow-button transition-all duration-500 overflow-hidden rounded-card ${
            openDropdown && 'open'
          }`}
          style={{
            maxHeight: openDropdown ? `${Languages.length * 52 + 8}px` : '52px',
          }}
        >
          <li
            color="white"
            className={`flex items-center justify-between text-xl font-body font-normal shadow-button mb-2 active:bg-gray-200 lg:hover:bg-gray-200 rounded-full text-secondary-500 w-[210px] h-13 px-2 py-2 relative cursor-pointer`}
            onClick={() => setOpenDropdown(!openDropdown)}
          >
            <img src={`/assets/images/lang/${lang}.png`} alt="" className="rounded-full size-10 border-1" />
            {Languages.find((l) => l.id === lang)?.label}
            <svg id="chevron" width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 8.66406L17.0623 0.601805" stroke="black" strokeLinecap="round" />
              <path d="M9.06226 8.66406L0.999998 0.601805" stroke="black" strokeLinecap="round" />
            </svg>
          </li>

          {Languages.map(
            (l) =>
              lang &&
              l.id !== lang && (
                <li
                  key={l.id}
                  color="white"
                  className={`flex items-center justify-start gap-x-4 text-xl font-body active:bg-gray-200 lg:hover:bg-gray-200 font-normal  rounded-none last:rounded-b-card text-secondary-500 w-[210px] h-13 px-2 py-2 pr-8 relative cursor-pointer`}
                  onClick={(e) => selectLanguageHandler(e, l.id)}
                >
                  <img src={`/assets/images/lang/${l.id}.png`} alt="" className="rounded-full border-1 size-10" />
                  {l.label}
                </li>
              )
          )}
        </ul>
      )}
    </div>
  );
};
