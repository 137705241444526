import {Avatar} from "flowbite-react";

type CardPictureOptions = {
    src: string;
    alt: string;
    name: string;
    bottomText: string;
    borderRadius?: number | string;
};

const CardProfilePicture = ({
                                src,
                                alt,
                                name,
                                bottomText,
                                borderRadius = "50%",
                            }: CardPictureOptions) => (
    <>
        <Avatar
            rounded
            img={({className, ...props}) => (
                <img
                    alt={alt ?? ""}
                    referrerPolicy="no-referrer"
                    src={src ?? ""}
                    className={className + " object-cover h-[50px] w-[50px] max-h-[50px] max-w-[50px]"}
                    {...props}
                    sizes="lg"
                />
            )}
        >
            <div className="space-y-1 font-medium dark:text-white">
                <div className="font-title text-xl">{name}</div>
            </div>
        </Avatar>
        <div className="text-sm text-gray-500 dark:text-gray-400 italic">
            {bottomText}
        </div>
    </>
);

export default CardProfilePicture;
