import { Button } from "flowbite-react";
import { PopupButton } from "react-calendly";
import { useTranslation } from "react-i18next";

export const ScheduleMeetingButton = ({ className = "" }) => {
  const { t } = useTranslation()
  return (
    <>
      <Button
        pill
        as={PopupButton}
        size="footer"
        url="https://calendly.com/mypoliseu/reuniao-mypolis"
        rootElement={document.getElementById("root") as HTMLElement}
        text={t("common.scheduleMeeting")}
        color="secondary"
        className={`py-2 m-auto text-2xl font-normal font-title whitespace-nowrap w-80 h-14 lg:px-20 ${className}`}
      />
    </>
  );
};
