import { CustomFlowbiteTheme } from 'flowbite-react';

const theme: CustomFlowbiteTheme = {
  accordion: {
    root: {
      base: 'flex flex-col border-none w-full px-4 lg:px-[88px]',
      flush: {
        off: '',
        on: '',
      },
    },

    content: {
      base: 'bg-white w-full rounded-t-none rounded-card pb-5 px-5 shadow-card font-body font-normal text-base lg:text-lg',
    },
    title: {
      base: 'font-body bg-white w-full flex flex-row items-center shadow-card p-4 text-left justify-between min-h-[88px] h-auto rounded-card mt-5 first:mt-0 ring-0 ring-transparent [-webkit-tap-highlight-color:transparent]',
      arrow: {
        base: 'hidden',
        open: {
          off: '',
          on: 'rotate-180',
        },
      },
      flush: {
        off: '',
        on: '',
      },
      heading: 'w-full flex justify-between items-center text-base font-body font-semibold lg:text-lg',
      open: {
        off: '',
        on: '',
      },
    },
  },
  button: {
    base: 'focus:outline-none shadow-button group flex items-stretch items-center justify-center p-0.5 text-center font-medium relative focus:z-10 focus:outline-none',
    color: {
      primary: 'bg-primary-500 hover:bg-primary-600 text-white',
      secondary: 'bg-secondary-500 text-white hover:bg-secondary-600',
      danger: 'bg-danger-500 text-white hover:bg-danger-600',
      white: 'bg-white text-secondary-500 hover:bg-gray-100',
      link: 'bg-white text-black hover:text-underline',
      bg: 'bg-bg-500 hover:bg-bg-600 text-secondary-500',
    },
    size: {
      xs: 'text-xs px-2 py-1',
      sm: 'text-sm px-3 py-1.5',
      md: 'text-sm px-4 py-2',
      lg: 'text-base px-5 py-2.5',
      xl: 'text-base px-6 py-3',
      footer: 'text-2xl px-4 py-2 font-normal',
      navbar: 'text-xs px-3 py-2 lg:text-sm lg:px-4 lg:py-2',
    },
  },
  navbar: {
    root: {
      inner: {
        base: 'mx-auto flex items-center h-full',
      },
    },

    collapse: {
      base: '',
      list: 'mt-4 flex flex-col md:mt-0 md:flex-row md:space-x-8 md:text-sm md:font-medium',
    },
  },
  textInput: {
    field: {
      input: {
        base: 'block w-full border disabled:cursor-not-allowed disabled:opacity-50 shadow-input bg-bg-500 border-0 focus-visible:!outline-none focus-visible:ring-1 focus-visible:ring-inset',
        colors: {
          input: 'bg-[#FDEFE1] placeholder-secondary-500 text-secondary-500 text-md  focus-visible:ring-primary-500',
          failure: 'border bg-danger-50 text-danger-900 placeholder-danger-700 focus-visible:ring-danger-500 ',
        },
      },
    },
  },
  textarea: {
    base: 'block w-full border disabled:cursor-not-allowed disabled:opacity-50 text-sm border-0 shadow-input placeholder:text-sm rounded-lg focus-visible:!outline-none focus-visible:ring-1 focus-visible:ring-inset',
    colors: {
      input: 'bg-[#FDEFE1] placeholder-secondary-500 text-secondary-500 text-md focus-visible:ring-primary-500',
      failure: 'bg-danger-50 text-danger-900 placeholder-danger-700 focus-visible:ring-danger-500 ',
    },
  },
  footer: {
    brand: {
      base: 'mb-4 flex items-center sm:mb-0 text-bg-500',
      img: 'w-60 h-auto',
    },
    title: {
      base: 'mb-5 text-sm font-semibold uppercase text-bg-500 font-body text-lg',
    },

    groupLink: {
      base: 'flex flex-wrap text-sm text-bg-500 font-body text-md',
      link: {
        base: 'focus:outline-none text-lg',
        href: 'focus:outline-none hover:underline',
      },
    },
    icon: {
      base: '',
      size: 'h-11 w-11 lg:h-12 lg:w-12',
    },
    copyright: {
      base: 'text-sm text-bg-500',
    },
  },
  card: {
    root: {
      base: 'flex rounded-card bg-white shadow-card m-2',
      children: 'flex h-full flex-col justify-center gap-2 w-full h-full',
    },
    img: {
      base: 'rounded-card',
      horizontal: {
        off: '',
      },
    },
  },
  modal: {
    root: {
      sizes: {
        sm: 'max-w-sm',
        md: 'max-w-md',
        lg: 'max-w-lg',
        xl: 'max-w-xl',
        '2xl': 'max-w-2xl',
        '3xl': 'max-w-3xl',
        '4xl': 'max-w-4xl',
        '5xl': 'max-w-5xl',
        '6xl': 'max-w-6xl',
        '7xl': 'max-w-7xl',
        screen: 'max-w-screen max-h-screen h-screen w-screen',
      },
    },
    content: {
      inner: 'relative rounded-card bg-white shadow-card dark:bg-gray-700 flex flex-col max-h-[90vh] py-6',
    },
  },
  spinner: {
    color: {
      failure: 'fill-danger-600',
      gray: 'fill-gray-600',
      info: 'fill-cyan-600',
      pink: 'fill-pink-600',
      purple: 'fill-purple-600',
      success: 'fill-green-500',
      warning: 'fill-yellow-400',
      primary: 'fill-primary-500',
    },
    size: {
      xs: 'w-3 h-3',
      sm: 'w-4 h-4',
      md: 'w-6 h-6',
      lg: 'w-8 h-8',
      xl: 'w-10 h-10',
      '3xl': 'w-30 h-30',
    },
  },
  dropdown: {
    content: 'focus:outline-none w-full h-full',
    floating: {
      base: 'flex rounded-full',
      item: {
        container: "h-full w-full",
        base: "flex justify-start align-center flex-grow items-center text-secondary-500 text-xl font-body font-normal w-full h-full px-4"
      }
    },
  },
};

export default theme;
