type ImpactDisplayerOptions = {
    imgSrc: string,
    title: string,
    description: string
}
export const ImpactDisplayer = ({imgSrc, title, description}: ImpactDisplayerOptions) => {
    return (
        <div className="flex flex-row lg:flex-col items-center py-2 pl-5 pr-10 lg:px-2 w-full lg:w-auto">
            <img className="max-w-[120px] lg:max-w-[200px]" src={imgSrc} alt={title}/>
            <div className="flex flex-col grow">
                <span className="text-3xl font-title font-medium text-secondary-500
                text-end lg:text-center ml-auto lg:ml-0">{title}</span>
                <span className="text-sm font-title text-secondary-500 max-w-[215px]
                text-end lg:text-center ml-auto lg:ml-0">{description}</span>
            </div>
        </div>
    );
};
