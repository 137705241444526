import { TextFigure } from '../../molecules/text-figure/text-figure';
import { Figure } from '../../atoms/figure/figure';
import { useTranslation } from 'react-i18next';

type historyListType = {
  title: string;
  text: string;
  image: string;
  description: string;
};


export const HistoryList = () => {
  const { t } = useTranslation(["translation", "history-items"])
  const historyList: Array<historyListType> = t("historyItems1", { ns: "history-items", returnObjects: true });

  const historyElement: historyListType = t("historyItems2", { ns: "history-items", returnObjects: true });

  return (
    <div className="z-10 flex flex-col mt-24 lg:mt-36">
      <div className="flex w-full mb-8 lg:mb-16">
        <div className="relative px-10 py-5 mx-auto bg-white lg:px-20 lg:py-7 rounded-card">
          <img
            src="/assets/images/other/white_star.svg"
            alt="Baloon"
            className="absolute -bottom-4 left-2 max-w-[22px] brightness-0
                        lg:-bottom-8 lg:-left-8 lg:max-w-[40px]"
          />
          <img
            src="/assets/images/other/white_star.svg"
            alt="Baloon"
            className="absolute -top-4 right-4 max-w-[19px] brightness-0
                        lg:-top-12 lg:right-1 lg:max-w-[32px]"
          />
          <img
            src="/assets/images/other/dialog_baloon_red_2.png"
            alt="Curve"
            className="absolute -top-8 left-10 max-w-[55px] lg:-top-18 lg:left-4 lg:max-w-[105px]"
          />
          <img
            src="/assets/images/other/pencil.png"
            alt="Baloon"
            className="absolute -bottom-8 -right-2 max-w-[110px]
                                   lg:-bottom-16 lg:-right-14 lg:max-w-[205px]"
          />
          <span className="text-2xl lg:text-2.5xl font-semibold font-title text-secondary-500">{t("history.title")}</span>
        </div>
      </div>

      <div className="relative z-10 flex flex-row flex-wrap justify-around mx-auto">
        {historyList && typeof historyList === "object" && historyList.map((el, i) => {
          return (
            <TextFigure
              title={el.title}
              text={el.text}
              maxLength="max-w-[85%]"
              srcImage={el.image}
              description={el.description}
              reverse={i % 2 !== 0}
              key={i}
            />
          );
        })}
      </div>

      <div className="relative flex justify-center w-full mt-10 mb-10 lg:mb-20">
        <Figure
          srcImage="/assets/images/mocks/mock_mc.png"
          description={t("firstFigureLabel", {ns: "history-items"})}
          maxWidth="max-w-[350px] lg:max-w-[500px]"
        />

        <img
          src="/assets/images/lines/spiral_down.png"
          alt="Curve"
          className="absolute -bottom-10 left-0 max-w-[115px] -scale-y-100 rotate-[35deg] lg:max-w-[175px]"
        />
      </div>

      {historyElement && typeof historyElement === "object" && <div className="relative z-10 flex flex-row flex-wrap justify-around mx-auto">
        <TextFigure
          title={historyElement.title}
          text={historyElement.text}
          maxLength="max-w-[85%]"
          srcImage={historyElement.image}
          description={historyElement.description}
        />
      </div>}

      <div className="relative flex justify-center w-full mt-20 mb-20 lg:mt-10">
        <Figure
          srcImage="/assets/images/history/MyPolis_team_2023.png"
          description={t("secondFigureLabel", {ns: "history-items"})}
          maxWidth="max-w-[350px] lg:max-w-[785px]"
        />

        <img
          src="/assets/images/other/rounded_red_baloon.png"
          alt="Curve"
          className="absolute -top-8 left-12 max-w-[65px] lg:-top-12 lg:left-40 lg:max-w-[105px]"
        />

        <img
          src="/assets/images/other/spring.png"
          alt="Curve"
          className="absolute top-6 -left-2 max-w-[65px] lg:top-14 lg:left-6 lg:max-w-[105px]"
        />

        <img
          src="/assets/images/other/baloon_yellow.png"
          alt="Curve"
          className="absolute -top-8 right-12 max-w-[65px] lg:-top-12 lg:right-40 lg:max-w-[105px]"
        />

        <img
          src="/assets/images/other/dialog_baloon_clear_sharp.png"
          alt="Curve"
          className="absolute top-28 right-3 max-w-[65px] lg:top-10 lg:right-14 lg:max-w-[105px]"
        />

        <img
          src="/assets/images/other/black_baloon.png"
          alt="Curve"
          className="absolute -bottom-2 right-14 max-w-[65px] lg:bottom-20 lg:right-14 lg:max-w-[105px]"
        />
      </div>

      <div className="flex flex-col w-full">
        <span className="relative mx-auto text-xl font-medium text-center font-title w-fit max-w-[288px] lg:max-w-[470px]">
          {t("testimony.text", {ns: "history-items"})}
          <img
            src="/assets/images/lines/curve.png"
            alt="Curve"
            className="absolute -left-12 -top-12 lg:-left-12 max-w-[50px]"
          />
          <img
            src="/assets/images/lines/curve.png"
            alt="Curve"
            className="absolute -bottom-6 -right-12 max-w-[50px] rotate-180"
          />
        </span>
        <span className="mx-auto mt-6 text-xl italic font-medium text-center w-fit">{t("testimony.label", {ns: "history-items"})}</span>
      </div>
    </div>
  );
};
