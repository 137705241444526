type CardVideoOptions = {
  src: string;
};

const CardVideo = ({ src }: CardVideoOptions) => {
  const video = document.createElement("video");
  video.className = "rounded-card";
  video.src = src;
  return <video src={src} className="rounded-card"></video>;
};

export default CardVideo;
