import { useTranslation } from 'react-i18next';
import { TeamCard } from '../../molecules/team-card/team-card';

type TeamCardType = {
  imageSrc: string;
  name: string;
  position: string;
  description: string;
  email: string;
  socials?: Array<{ social: string; link: string }>;
};

function randomNumberInRange(): number {
  // 👇️ get number between min (inclusive) and max (inclusive)
  return Math.floor(Math.random() * (10 - 0 + 1)) + 0;
}

export const TeamList = () => {
  const { t } = useTranslation(["translation", "team-members"])

  const teamListCol1 = t("teamMembers1", { ns: "team-members", returnObjects: true }) as TeamCardType[]
  const teamListCol2 = t("teamMembers2", { ns: "team-members", returnObjects: true }) as TeamCardType[]
  const teamListCol3 = t("teamMembers3", { ns: "team-members", returnObjects: true }) as TeamCardType[]
  const teamList: TeamCardType[][] = [
    teamListCol1 && typeof teamListCol1 === "object" ? teamListCol1 : [],
    teamListCol2 && typeof teamListCol2 === "object" ? teamListCol2 : [],
    teamListCol3 && typeof teamListCol3 === "object" ? teamListCol3 : [],
  ]

  const teamListMobile = t("teamMembersMobile", { ns: "team-members", returnObjects: true }) as TeamCardType[]
  return (
    <div className="z-10 flex flex-col mt-24 lg:mt-36">
      <div className="flex w-full mb-8 lg:mb-16">
        <div className="relative px-12 mx-auto bg-white lg:px-20 py-7 rounded-card">
          <img
            src="/assets/images/lines/spiral_down.png"
            alt="Baloon"
            className="absolute -top-20 lg:-top-24 -left-20 max-w-[125px] rotate-2 lg:-left-14 lg:max-w-[155px]"
          />
          <img
            src="/assets/images/other/yellow_baloon_straight.png"
            alt="Baloon"
            className="absolute -top-10 right-10 max-w-[80px] lg:-top-14 lg:right-12 lg:max-w-[110px]"
          />
          <span className="flex text-center text-2xl sm:text-2.5xl font-semibold font-title text-secondary-500">
            {t("team.title1")}&nbsp;
            <br className="block lg:hidden" />{t("team.title2")}
          </span>
          <img
            src="/assets/images/lines/curve.png"
            alt="Curve"
            className="absolute top-2 left-3 lg:left-10 max-w-[35px] rotate-[5deg]"
          />

          <img
            src="/assets/images/lines/curve.png"
            alt="Curve"
            className="absolute bottom-2 right-9 max-w-[35px] rotate-[175deg] block lg:hidden"
          />
        </div>
      </div>

      <div className="relative z-10 flex-row flex-wrap justify-around hidden mx-auto gap-x-8 lg:flex">
        {teamList && teamList.map((chunk, i) => (
          <div key={i} className="flex flex-col gap-y-8">
            {chunk.map((el, i) => {
              return (
                <TeamCard
                  key={`${el.name} ${i}`}
                  imageSrc={el.imageSrc}
                  name={el.name}
                  position={el.position}
                  description={el.description}
                  email={el.email}
                  socials={el.socials}
                  index={randomNumberInRange()}
                ></TeamCard>
              );
            })}
          </div>
        ))}
      </div>

      <div className="relative z-10 flex flex-col justify-center gap-8 mx-auto lg:hidden">
        {teamListMobile && typeof teamListMobile === "object" && teamListMobile.map((el, i) => {
          return (
            <TeamCard
              key={`${el.name} ${i}`}
              imageSrc={el.imageSrc}
              name={el.name}
              position={el.position}
              description={el.description}
              email={el.email}
              socials={el.socials}
              index={randomNumberInRange()}
            ></TeamCard>
          );
        })}
      </div>
    </div>
  );
};
