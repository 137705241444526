import { Flowbite } from 'flowbite-react';
import { useContext, useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { AppContext } from './contexts/app/app-context';
import Router from './routes';
import theme from './theme';
import { useTranslation } from 'react-i18next';


const loadFonts = async (fonts: string[]) => {
  await document.fonts.ready;
  for (let f of fonts) {
    await document.fonts.load(`12px ${f}`);
  }
};

const App = () => {
  const [isReady, setIsReady] = useState(false);
  const { setFireAnimations } = useContext(AppContext);
  const { i18n } = useTranslation()
  useEffect(() => {
    loadFonts(['Unbounded', 'Inter']).then(() => setIsReady(true));
    setTimeout(() => setIsReady(true), 1500);
    setTimeout(() => setFireAnimations(true), 5000);
  }, [setIsReady, setFireAnimations]);

  return (
    <Flowbite theme={{ theme }}>
      {isReady && (
        <RouterProvider router={Router(i18n.language)} />
      )}
    </Flowbite>
  );
};

export default App;
