import { TextInput } from 'flowbite-react';
import { useFormContext } from 'react-hook-form';

type FormTextInputProps = {
  id: string;
  name: string;
  placeholder: string;
};

export const FormTextInput = ({ name, placeholder, id }: FormTextInputProps) => {
  const { register, getFieldState, formState } = useFormContext();

  const { isDirty, isTouched, invalid, error } = getFieldState(name, formState);
  return (
    <TextInput
      id={id}
      {...register(name)}
      placeholder={placeholder}
      color={`${(isTouched || isDirty || invalid) && (error ?? false) ? 'failure' : 'input'}`}
      helperText={
        <>{((isTouched || isDirty || invalid) && error && <span className="text-xs pl-2 pt-1">{error.message}</span>) ?? ''}</>
      }
    />
  );
};
