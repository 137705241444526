import { Button, Card } from "flowbite-react";
import { ImplementedProposal } from "../../../api";
import CardSubtitle from "../../atoms/card/card-subtitle";
import CardTitle from "../../atoms/card/card-title";
import "./proposal-card.scss";

type ProposalCardOptions = {
  proposal: ImplementedProposal;
  showMoreClickHandler: React.Dispatch<any>;
  activeProposal: ImplementedProposal | undefined;
  activeProposalHandler: React.Dispatch<any>;
};

export const ProposalCard = ({
  proposal,
  showMoreClickHandler = () => {},
  activeProposal,
  activeProposalHandler = () => {},
}: ProposalCardOptions) => {
  return (
    <Card key={proposal.id} className="w-64 h-80 card proposal-card">
      <div className="flex flex-col h-full w-full p-5">
        <div
          className={`image w-full h-auto grow rounded-card ${
            !proposal.banner && "hidden"
          }`}
          style={{ backgroundImage: `url("${proposal?.banner}")` }}
        ></div>
        <div className="body-container mt-4 flex flex-col justify-center w-full flex-grow">
          <div className="title w-full mb-2">
            <CardTitle
              className="leading-6"
              text={proposal.title ?? ""}
            ></CardTitle>
          </div>
          <div className="flex flex-row justify-evenly h-full">
            <div className="date flex flex-grow">
              <CardSubtitle text={proposal.subtitle ?? ""}></CardSubtitle>
            </div>
            <div className="button flex flex-col mt-auto mr-1 ml-auto">
              <Button
                pill
                id="flip"
                color="white"
                className="bg-white rounded-full shadow-button w-12 h-12 flex flex-col justify-center cursor-pointer border-primary-200 border-solid"
                onClick={() => {
                  activeProposalHandler(proposal);
                  showMoreClickHandler(true);
                }}
              >
                <svg
                  className="w-8 h-8 m-auto text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 1v16M1 9h16"
                  />
                </svg>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
