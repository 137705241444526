import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../contexts/app/app-context';
import { CarouselItem } from '../../atoms/carousel/carousel-item';
import './customer-carousel.scss';
import { Customer } from '../../../api';

export const CustomerCarousel = () => {
  const { t } = useTranslation(['customers']);
  const customers: Customer[] = t('customers', { returnObjects: true });
  const { fireAnimations } = useContext(AppContext);

  const logos = customers.map((customer, i) => {
    return (
      customer.visibleInUi && <CarouselItem key={i} imgUrl={customer.logo} imgTitle={customer.name}></CarouselItem>
    );
  });

  console.log(customers);
  console.log(logos);

  return (
    <div
      className={
        'w-full absolute h-24 overflow-x-auto bg-white carousel-container shadow-carousel ' +
        'lg:overflow-x-hidden lg:w-[calc(100vw+30px)] lg:-left-2 lg:rotate-5'
      }
    >
      <div
        className={`flex justify-start h-full py-4 bg-white carousel-track w-fit ${fireAnimations ? 'animate' : ''}`}
      >
        {logos}
        {logos}
        {logos}
        {logos}
      </div>
    </div>
  );
};
