import { useTranslation } from 'react-i18next';
import { Figure } from '../../atoms/figure/figure';

type Prize = {
  logo: string;
  description: string;
  className?: string;
};


export const Prizes = () => {
  const { t } = useTranslation(["translation", "awards"])
  const prizesList: Array<Prize> = t("awards", { ns: "awards", returnObjects: true });
  return (
    <div className="hero-how-to-participate font-title w-full text-center lg:px-[45px]">
      <div className="relative flex mx-auto mt-24 mb-12">
        <span className="w-full m-auto text-xl font-semibold leading-9">{t("history.awards")}</span>
        <img
          src="/assets/images/prizes/prizes.png"
          alt="Curve"
          className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 max-w-[275px]"
        />
      </div>
      <div className="flex flex-row flex-wrap justify-around w-full gap-8 mt-12 mb-8">
        {prizesList && typeof prizesList === "object" && prizesList.map((el, i) => {
          return (
            <Figure
              key={i}
              className="m-auto max-w-[225px]"
              description={el.description}
              maxWidth={`max-w-[200px] rounded-none lg:mx-auto ${el.className}`}
              srcImage={el.logo}
              textWeight="font-normal"
            />
          );
        })}
      </div>
    </div>
  );
};
