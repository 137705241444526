import Municipalities from './municipalities';
import i18n from 'i18next';

i18n.loadNamespaces(['routes']);

const municipalitiesPageRoutes = [
  { path: 'autarquias', element: <Municipalities /> },
  { path: 'local-authorities', element: <Municipalities /> },
];

export default municipalitiesPageRoutes;
