type GeometryType =
  | 'Point'
  | 'MultiPoint'
  | 'LineString'
  | 'MultiLineString'
  | 'Polygon'
  | 'MultiPolygon'
  | 'GeometryCollection';

type LngLat = Array<number>;

export type GeoJsonFeatureCollection = {
  type: 'FeatureCollection';
  features: GeoJsonFeature[];
};

type GeoJsonFeature = {
  type: 'Feature';
  id: string;
  geometry: {
    type: GeometryType;
    coordinates: LngLat;
  };
  properties: any;
};

type GeoJsonObject = GeoJsonFeature | GeoJsonFeatureCollection;

export const convertObjectToGeoJson = (data: any, type: GeometryType = 'Point'): GeoJsonObject => {
  if (!data || typeof data === 'undefined' || data === null) throw new Error('Data object cannot be empty');

  let geoJson: GeoJsonObject;

  if (Array.isArray(data)) {
    if (!data[0]?.location) throw new Error("The items must contain 'Location' property.");

    geoJson = {
      type: 'FeatureCollection',
      features: data.map((item) => {
        return {
          type: 'Feature',
          geometry: {
            type,
            coordinates: [item.location.longitude, item.location.latitude],
          },
          id: item.id,
          properties: { data: JSON.stringify(item) },
        };
      }),
    };
  } else {
    if (!data?.location) throw new Error("The object must contain 'Location' property.");

    geoJson = {
      type: 'Feature',
      id: data.id,
      geometry: {
        type,
        coordinates: [data.location.longitude, data.location.latitude],
      },
      properties: { data: JSON.stringify(data) },
    };
  }

  return geoJson;
};

export const slugify = (str: string) =>
  str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

export const chunkify = <T = any>(a: Array<T>, n: number, balanced: boolean = false) => {
  if (n < 2) return [a];

  var len = a.length,
    out = [],
    i = 0,
    size;

  if (len % n === 0) {
    size = Math.floor(len / n);
    while (i < len) {
      out.push(a.slice(i, (i += size)));
    }
  } else if (balanced) {
    while (i < len) {
      size = Math.ceil((len - i) / n--);
      out.push(a.slice(i, (i += size)));
    }
  } else {
    n--;
    size = Math.floor(len / n);
    if (len % size === 0) size--;
    while (i < size * n) {
      out.push(a.slice(i, (i += size)));
    }
    out.push(a.slice(size * n));
  }

  return out;
};

export const simpleSortForOrderedObjects = <T extends { order: number }>(a: T, b: T) =>
  a.order && b.order && a < b ? -1 : a > b ? 1 : 0;