import { useContext } from 'react';
import { AppContext } from '../../../contexts/app/app-context';
import { ScheduleMeetingButton } from '../../atoms/schedule-meeting-button/schedule-meeting-button';
import './hero-municipalities.scss';
import { useTranslation } from 'react-i18next';

export const HeroMunicipalities = ({ className }: any) => {
  const { fireAnimations } = useContext(AppContext);
  const { t } = useTranslation()

  return (
    <div className={`hero-municipalities gap-x-2 flex justify-around w-full pt-10 -mb-24 ${className ?? ""}`}>
      <div className="flex flex-col items-center justify-around w-full lg:w-1/2 gap-y-10 lg:px-4">
        <div className="flex flex-col items-start justify-center w-full text-3xl lg:text-5xl text-secondary-500 font-title gap-y-2 lg:-rotate-5">
          <div className="flex flex-col mx-auto w-fit">
            <div className="flex px-4 mt-8 lg:py-2 lg:bg-yellow-300">
              <span className="">
                {t("localAuthorities.hero.participation")}
              </span>
            </div>

            <div className="z-0 flex items-center px-3 mt-2">
              <span className="flex flex-row">
                {t("localAuthorities.hero.of")}&nbsp;
                <span className="flex flex-col gap-y-2">
                  {t("localAuthorities.hero.youngPeople")}&nbsp;
                  <img
                    className="-mt-6 rotate-6"
                    src="/assets/images/lines/downward_curve.svg"
                    alt="Downward curving line"
                  />
                </span>
              </span>
            </div>
            <div className="z-10 flex flex-row px-4 -mt-6">
              <span className="py-2">{t("localAuthorities.hero.starts")}&nbsp;</span>
              <span className="px-4 py-2 text-white rounded-full w-max bg-primary-500 lg:text-secondary-500 lg:bg-yellow-300">
                {t("localAuthorities.hero.here")}
              </span>
            </div>
          </div>

          <img
            className="block lg:hidden w-[375px] mx-auto rotate-5"
            src="/assets/images/other/handshake_mobile.png"
            alt="Handshake"
          />

          <div className="px-4 -mt-10 text-xl text-primary-900/50 lg:mt-2">
            {t("localAuthorities.hero.results")}
          </div>
        </div>
        <div className="relative flex flex-col w-5/6 mt-4 mr-auto lg:mr-0">
          <div className="w-full px-4">
            <ScheduleMeetingButton className="w-full h-12 text-base font-medium" />
          </div>

          <img
            className={`rotate-180 w-1/4 absolute left-0 lg:left-32 max-w-[120px] ${fireAnimations ? 'animate' : ''}`}
            id="arrow-down-twirl"
            src="/assets/images/arrows/arrow_down_twirl.png"
            alt="Arrow down"
          />
        </div>
      </div>

      <div className="hidden w-1/2 lg:flex">
        <img className="object-contain w-4/5 mx-auto" src="/assets/images/other/handshake.png" alt="Handshake" />
      </div>
    </div>
  );
};
