type CardTitleOptions = {
  text: string;
  fontFamily?: string;
  className?: string;
};

const CardTitle = ({ text, fontFamily, className }: CardTitleOptions) => (
  <h3
    className={`text-base lg:text-xl ${className ?? ""}`}
    style={{
      fontFamily,
    }}
  >
    {text}
  </h3>
);

export default CardTitle;
