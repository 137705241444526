import { sanitize } from "dompurify";

export const StepFigure = ({
  srcImage,
  description,
  maxWidth = 'max-w-[350px]',
  className,
}: {
  srcImage: string;
  description: string;
  maxWidth?: string;
  className?: string;
}) => {
  return (
    <div className={`flex flex-col w-auto ${className ?? ''}`}>
      <img src={srcImage} alt="Impact" className={`mx-auto rounded-card max-h-36 lg:mx-0 lg:max-h-none`} />

      <span
        className="px-10 mt-4 text-xs italic font-medium text-center"
        dangerouslySetInnerHTML={{ __html: sanitize(description) }}
      ></span>
    </div>
  );
};
