import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tool } from '../../../api';
import { chunkify } from '../../../utilities/index';
import { ToolListCard } from '../../molecules/tool-list-card/tool-list-card';
import { ToolModal } from '../../molecules/tool-modal/tool-modal';

export type Category = { id: string; title: string; order: number };

export type Categories = {
  [key: string]: string;
};

export const ToolsList = () => {
  const { t } = useTranslation(["translation", "tools"]);
  const tools: Tool[] = t("tools", { ns: "tools", returnObjects: true })
  const [activeTool, setActiveTool] = useState<Tool | undefined>();
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      {tools && typeof tools === "object" && (
        <div className="z-10 flex flex-col items-center w-full mt-24 mb-12 tools-list lg:mt-36">
          <div className="flex flex-col mx-auto w-fit mb-14 lg:mb-16">
            <div className="relative mx-auto text-center bg-white px-18 py-7 lg:px-28 lg:py-12 rounded-card">
              <img
                src="/assets/images/other/tools_baloons_left.png"
                alt="Baloon"
                className="absolute left-4 h-[190px] -top-10 block lg:hidden"
              />
              <span className="text-2xl sm:text-2.5xl font-semibold font-title text-secondary-500">
                {t("tools.title1")}&nbsp;
                <br className="block" />{t("tools.title2")}
              </span>
              <img
                src="/assets/images/other/tools_baloons_right.png"
                alt="Curve"
                className="absolute h-[200px] right-4 -top-7 block lg:hidden"
              />
              <img
                src="/assets/images/tools/tools-list/title_desktop.png"
                alt="Curve"
                className="absolute max-w-[465px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 hidden lg:block"
              />
            </div>
            <div className="px-4 mt-12 text-base font-normal font-title text-primary-900/50">
              {t("tools.subTitle1")}&nbsp;
              <br className="lg:hidden" />
              {t("tools.subTitle2")}&nbsp;
              <br className="hidden lg:block" />
              {t("tools.subTitle3")}&nbsp;
              <br className="lg:hidden" />
              {t("tools.subTitle4")}&nbsp;
              <br className="lg:hidden" />
              {t("tools.subTitle5")}
            </div>
          </div>
          <div className="relative grid w-full grid-cols-1 gap-y-8 gap-x-8 px-11 lg:grid-flow-row lg:grid-cols-2">
            <img
              className="absolute hidden w-52 lg:block -left-32 -top-32 -z-10"
              src="/assets/images/tools/tools-list/white_n_yellow_baloon.png"
              alt="Balões de fala"
            />
            <img
              className="w-14 absolute rotate-[105deg] hidden lg:block -right-6 -top-12 -z-10"
              src="/assets/images/tools/tools-list/serpentine.png"
              alt="Balões de fala"
            />
            <img
              className="w-36 absolute hidden lg:block -left-15 top-[390px] -z-10"
              src="/assets/images/tools/tools-list/game_controller_rotated_left.png"
              alt="Balões de fala"
            />
            <img
              className="absolute hidden w-32 lg:block -right-7 top-48 -z-10"
              src="/assets/images/tools/tools-list/spring_rotated_right.png"
              alt="Balões de fala"
            />
            <img
              className="w-24 absolute hidden rotate-[30deg] lg:block -right-16 top-[405px] -z-10"
              src="/assets/images/other/dialog_baloon_red_2.png"
              alt="Balões de fala"
            />
            <img
              className="w-28 absolute hidden lg:block -right-8 top-[820px] -z-10"
              src="/assets/images/tools/tools-list/phone_transparent.png"
              alt="Balões de fala"
            />
            <img
              className="w-40 absolute hidden -rotate-2 lg:block -left-[82px] top-[955px] -z-10"
              src="/assets/images/other/star.png"
              alt="Balões de fala"
            />
            <img
              className="w-10 absolute hidden lg:block right-4 top-[1405px] -z-10 brightness-0"
              src="/assets/images/other/white_star.svg"
              alt="Balões de fala"
            />
            <img
              className="w-20 absolute hidden lg:block -left-9 top-[1410px] -z-10"
              src="/assets/images/other/purple_baloon.png"
              alt="Balões de fala"
            />
            <img
              className="w-20 absolute hidden lg:block -left-8 top-[1820px] -z-10"
              src="/assets/images/other/explosion.png"
              alt="Balões de fala"
            />
            <img
              className="w-36 absolute hidden lg:block -right-2 top-[1858px] -z-10"
              src="/assets/images/other/white_baloon.png"
              alt="Balões de fala"
            />
            <img
              className="w-10 absolute hidden lg:block mx-auto top-[2200px] left-1/2 -translate-x-[36%] -z-10 brightness-0"
              src="/assets/images/other/white_star.svg"
              alt="Balões de fala"
            />
            <img
              className="w-28 absolute hidden lg:block right-16 top-[2390px] -z-20"
              src="/assets/images/tools/tools-list/game_controller_rotated_right.png"
              alt="Balões de fala"
            />
            {chunkify<Tool>(tools, 2, true).map((chunk, i) => (
              <div key={i} className="flex flex-col w-full gap-y-8">
                {chunk.map((tool) => (
                  <ToolListCard
                    key={tool.id}
                    showTargets
                    tool={tool}
                    activeToolHandler={setActiveTool}
                    showMoreClickHandler={setOpenModal}
                  />
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
      <ToolModal modalCloseHandler={setOpenModal} openModal={openModal} tool={activeTool as Tool} />
    </>
  );
};
