import { Button, Card } from 'flowbite-react';
import CardSubtitle from '../../atoms/card/card-subtitle';
import CardTitle from '../../atoms/card/card-title';
import { Tool } from '../../../api';
import { useState } from 'react';
import './tool-list-card.scss';
import { useTranslation } from 'react-i18next';
type ToolListCardOptions = {
  className?: string;
  showTargets?: boolean;
  tool: Tool;
  showMoreClickHandler: React.Dispatch<any>;
  activeToolHandler: React.Dispatch<any>;
};

export const ToolListCard = ({
  className = '',
  showTargets = false,
  tool,
  showMoreClickHandler = () => {},
  activeToolHandler = () => {},
}: ToolListCardOptions) => {
  const [targetsVisible, setTargetsVisible] = useState(false);
  const {t} = useTranslation()

  const checkTargets = () => {
    if (!targetsVisible) setTargetsVisible(true);
  };

  return (
    <Card className="w-full m-0 h-fit card tool-list-card">
      <div className="flex flex-row flex-wrap p-5">
        <div
          id="image"
          className="flex-grow w-full rounded-card border-1 max-h-[206px]"
          style={{
            background: `url(${tool?.banner})`,
          }}
        >
          <img src={tool.banner} className="opacity-0" alt="" />
        </div>
        <div className="flex flex-col justify-center w-full mt-4 body-container lg:mt-3">
          <div className="flex flex-col w-full mb-2 title lg:flex-col-reverse">
            {showTargets &&
              tool.technicalSheet &&
              tool.technicalSheet.target &&
              tool.technicalSheet.target.length > 0 && (
                <div className="flex flex-row flex-wrap items-start justify-start mb-1 targets gap-x-1 gap-y-2 lg:mb-0 lg:mt-1">
                  {(() => {
                    checkTargets();
                    return <></>;
                  })()}
                  {tool.technicalSheet.target.map((target, i) => (
                    <span
                      key={`${target} ${i}`}
                      className="px-3 py-1 text-xs font-light border-solid rounded-full w-fit border-secondary-900/40 border-1"
                    >
                      {target}
                    </span>
                  ))}
                </div>
              )}
            <CardTitle className={`leading-6 ${!targetsVisible && 'lg:-mb-8'}`} text={tool.title ?? ''}></CardTitle>
          </div>
          <div className="flex flex-col w-full lg:mb-auto lg:flex-grow">
            <div className="w-full">
              <CardSubtitle text={tool.description ?? ''}></CardSubtitle>
            </div>
            <div className="w-full mx-auto mt-4 button">
              <Button
                pill
                color="primary"
                className="text-base font-semibold h-10 w-full lg:w-[168px] lg:mr-auto"
                onClick={() => {
                  activeToolHandler(tool);
                  showMoreClickHandler(true);
                }}
              >
                {t("common.moreInfo")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
