import { useTranslation } from 'react-i18next';
import { TextFigure } from '../../molecules/text-figure/text-figure';

type impactListType = {
  title: string;
  text: string;
  image: string;
  description: string;
};


export const ImpactList = () => {
  const { t } = useTranslation(["translation", "impact-items"])
  const impactList: Array<impactListType> = t("impactItems", { ns: "impact-items", returnObjects: true });
  return (
    <div className="flex flex-col items-center mt-20 impact-list">
      <div className="relative flex mb-8">
        <img
          src="/assets/images/other/white_star.svg"
          alt="Curve"
          className="absolute -bottom-6 -left-8 brightness-0 max-w-[35px]"
        />
        <span className="my-auto text-2xl font-semibold font-title text-secondary-500">{t("impact.list.title")}</span>
        <img src="/assets/images/lines/curve.svg" alt="Curve" className="absolute -top-6 -right-6 rotate-[115deg]" />
      </div>

      {impactList.map((el, i) => {
        return (
          <TextFigure
            title={el.title}
            text={el.text}
            maxLength="max-w-[85%]"
            srcImage={el.image}
            description={el.description}
            reverse={i % 2 !== 0}
            key={i}
          />
        );
      })}
    </div>
  );
};
