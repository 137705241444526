import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { Button } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import toast from 'react-simple-toasts';
import { Category } from '../../../utilities/enums';
import { FormHiddenInput } from '../../atoms/form-fields/form-hidden-input';
import { FormTextInput } from '../../atoms/form-fields/form-text-input';
import { FormTextArea } from '../../atoms/form-fields/form-textarea';
import { SuccessToast } from '../../atoms/form-toasts/sucess-toast';
import { PoliticianFormInputs, politicianFormSchema } from './schemas';

export const PoliticianForm = () => {
  const [hasErrors, setHasErrors] = useState(false);
  const {t} = useTranslation();

  const methods = useForm<PoliticianFormInputs>({
    resolver: yupResolver(politicianFormSchema),
    defaultValues: {
      'g-recaptcha-response': undefined,
      email: '',
      fullName: '',
      message: '',
      phone: '',
      messageType: Category.POLITICIAN,
      cityCounsel: undefined,
      politicianRole: undefined,
    },
  });

  useEffect(() => {
    methods.setValue('messageType', Category.POLITICIAN);
    setHasErrors(false);
  }, [methods]);

  const { isSubmitting } = useFormState<PoliticianFormInputs>({ control: methods.control });

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const onError: SubmitErrorHandler<PoliticianFormInputs> = (errors, e) => {
    setHasErrors(true);
  };

  const onSubmit: SubmitHandler<PoliticianFormInputs> = async (data) => {
    try {
      return await recaptchaRef.current
        ?.executeAsync()
        .then(async (token) => {
          data['g-recaptcha-response'] = token ?? undefined;

          return await axios
            .post(process.env.REACT_APP_EMAIL_LAMBDA_URL as string, data)
            .then((result) => {
              toast(<SuccessToast />);
              return;
            })
            .catch((error) => {
              // toast(<ErrorToast />);
              console.error(error);
            });
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.error(error);
    } finally {
      recaptchaRef.current?.reset();
    }
  };

  return (
    <div className={'flex flex-col items-center w-full relative'}>
      <div className="flex justify-center w-full -mt-10">
        <img
          src="/assets/images/other/dialog_baloons_spread.png"
          alt="Baloons"
          className="hidden lg:block max-w-[1050px] mx-auto"
        />

        <img
          src="/assets/images/other/baloons_mobile.png"
          alt="Baloons"
          className="block lg:hidden max-w-[875px] mt-0 -ml-24"
        />
      </div>

      <div className="flex flex-col items-center absolute top-[215px] left-1/2 -translate-x-1/2 w-full">
        <span className="text-[1.75rem] font-semibold font-title text-secondary-500 text-center max-w-[450px]">
          {t("forms.steps.title")}
        </span>
        <p className="pb-5 text-base text-center text-secondary-500 pt-7">{t("forms.steps.subText")}:</p>
        <div className="flex flex-col">
          <div className="flex mx-auto min-w-[315px] mb-6">
            <span className="text-2xl font-semibold font-title text-secondary-500 w-[20px] mr-4">1</span>
            <span className="pt-1 text-lg font-semibold text-secondary-500" dangerouslySetInnerHTML={{__html: t("forms.steps.one")}}>
            </span>
          </div>

          <div className="flex mx-auto min-w-[315px] mb-6">
            <span className="text-2xl font-semibold font-title text-secondary-500 w-[20px] mr-4">2</span>
            <span className="pt-1 text-lg font-semibold text-secondary-500" dangerouslySetInnerHTML={{__html: t("forms.steps.two")}}>
            </span>
          </div>

          <div className="flex mx-auto min-w-[315px]">
            <span className="text-2xl font-semibold font-title text-secondary-500 w-[20px] mr-4">3</span>
            <span className="pt-1 text-lg font-semibold text-secondary-500">{t("forms.steps.three")}</span>
          </div>
        </div>
      </div>
      <div className="relative flex flex-row items-start justify-center w-full mb-8 gap-x-10 forms">
        <div className="flex w-full px-6 lg:px-0">
          <div className="flex flex-col justify-end w-full gap-y-4">
            <span
              className="text-[1.75rem] font-semibold font-title text-secondary-500 text-center
                            mt-4 mb-6"
            >
              {t("forms.contactUs")}
            </span>
            <FormProvider {...methods}>
              <form
                id="contact-form"
                onSubmit={methods.handleSubmit(onSubmit, onError)}
                className={`flex flex-col z-10 max-w-[993px] gap-y-2 lg:px-[90px]`}
              >
                <FormHiddenInput name="messageType" id="message-type" />
                <div className="flex flex-col gap-x-4 lg:flex-row">
                  <div className={`flex flex-col w-full lg:w-2/5 ${hasErrors ? 'gap-y-2' : 'gap-y-4'}`}>
                    <div className="w-full">
                      <FormTextInput name="fullName" id="full-name" placeholder={t("forms.fields.name")} />
                    </div>
                    <div className="w-full">
                      <FormTextInput name="politicianRole" id="politician-role" placeholder={t("forms.fields.function")} />
                    </div>
                    <div className="w-full">
                      <FormTextInput name="cityCounsel" id="city-counsel" placeholder={t("forms.fields.municipality")} />
                    </div>
                    <div className="w-full">
                      <FormTextInput name="email" id="email" placeholder={t("forms.fields.email")} />
                    </div>
                    <div className="w-full">
                      <FormTextInput name="phone" id="phone" placeholder={t("forms.fields.phone")} />
                    </div>
                  </div>
                  <div className="flex w-full pt-4 lg:w-3/5 lg:pt-0">
                    <div className="flex flex-col w-full" id="textarea">
                      <FormTextArea
                        name="message"
                        id="message"
                        placeholder={t("forms.fields.message")}
                        rows={10}
                        className="flex-grow"
                      />
                    </div>
                  </div>
                </div>
                {createPortal(
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY as string}
                    size="invisible"
                    ref={recaptchaRef}
                  />,
                  document.body
                )}
                <div className="flex w-full">
                  <Button pill color="primary" type="submit" className="w-32 ml-auto" isProcessing={isSubmitting}>
                  {t("forms.sendButton")}
                  </Button>
                </div>
              </form>
            </FormProvider>

            <img
              src="/assets/images/other/spring.png"
              alt=""
              className="absolute -left-4 -bottom-16 lg:left-0 lg:top-[400px] lg:bottom-auto
                                max-w-[143px] z-0"
            />

            <img
              src="/assets/images/other/white_star.svg"
              alt=""
              className="absolute right-2 -bottom-24 lg:right-[40px] lg:top-[450px] lg:bottom-auto
                                max-w-[38px] z-0"
            />

            <img
              src="/assets/images/other/yellow_dialog_balloon.png"
              alt=""
              className="absolute right-36 -bottom-24 lg:right-[200px] lg:top-[400px] lg:bottom-auto
                                 max-w-[110px] z-0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
