import { useTranslation } from 'react-i18next';
import { Customer } from '../../../api';
import { ImageGridDisplay } from '../../molecules/image-grid-display/image-grid-display';

type HowToParticipateProps = {
  className?: string;
};

export const HeroHowToParticipate = ({ className = '' }: HowToParticipateProps) => {
  const { t } = useTranslation(['translation', 'customers']);

  const customers: Customer[] = t('customers', { ns: 'customers', returnObjects: true });
  const customerLogos: false | { src: string; alt: string }[] =
    customers &&
    typeof customers === 'object' &&
    customers
      .filter((c) => c.visibleInUi)
      .map((customer) => ({
        src: customer.logo,
        alt: customer.name,
      }));

  return (
    <div className={`${className} hero-how-to-participate font-title w-full text-center lg:px-[45px]`}>
      <div className="flex w-54 h-[110px] mt-12 mb-13 bg-white rounded-card  mx-auto relative py-4 px-8 lg:w-[375px] lg:h-[160px]">
        <span className="w-full font-semibold leading-9 text-xl m-auto lg:text-2.5xl">{t('htp.hero.title')}</span>
        <img
          src="/assets/images/lines/curve.svg"
          alt="Curve"
          className="absolute -scale-100 rotate-[25deg] bottom-2 right-5"
        />
        <img src="/assets/images/lines/curve.svg" alt="Curve" className="absolute top-[1px] left-12 rotate-[45deg]" />
        <img
          className="absolute scale-75 -bottom-12 -right-[4.4rem] lg:scale-[65%] lg:-bottom-18 lg:-right-32"
          src="/assets/images/other/point_up_baloons.png"
          alt="Plant"
        />
        <img
          className="absolute scale-[70%] -bottom-[4.2rem] -left-[6.3rem] lg:scale-[65%] lg:-bottom-24 lg:-left-36"
          src="/assets/images/other/high_five_star.png"
          alt="Plant"
        />
      </div>
      <div className="mx-auto w-fit text-base text-primary-900/50 mb-8 max-w-[212px]"></div>
      {customerLogos && <ImageGridDisplay className="mb-5" items={customerLogos} />}
      {/* <Link onClick={(e) => e.preventDefault()} to="/" className="text-sm underline font-body text-primary-500">
        Não encontras a tua comunidade?
      </Link> */}
    </div>
  );
};
