import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tool } from '../../../api';
import { ToolCard } from '../../molecules/tool-card/tool-card';
import { ToolModal } from '../../molecules/tool-modal/tool-modal';

export const Tools = ({ schoolsPage = false }) => {
  const [openModal, setOpenModal] = useState(false);
  const [activeTool, setActiveTool] = useState<Tool>();
  // const [tools, setTools] = useState<Tool[]>([]);

  const { t } = useTranslation(["translation", "tools"])
  const tools: Tool[] = t("tools", { ns: "tools", returnObjects: true })

  return (
    <>
      {tools && typeof tools === "object" && (
        <div className="flex flex-col items-center">
          <div className="flex flex-col items-center gap-y-6">
            <div className="relative m-auto mt-24">
              <span
                className="flex text-2.5xl font-semibold font-title relative
                    px-4 whitespace-break-spaces text-center lg:px-0 mx-auto"
              >
                {t("tools.title1")} {t("tools.title2")}
                <img
                  src="/assets/images/lines/spiral.png"
                  alt=""
                  className={
                    'absolute -top-24 -left-4 lg:-top-16 lg:-left-72 w-28 -rotate-[10deg] ' + (schoolsPage ? 'hidden' : '')
                  }
                />
              </span>

              <img
                src="/assets/images/other/schools_tools.png"
                alt=""
                className={
                  'absolute top-[50%] left-[50%] ' +
                  '-translate-x-[50%] -translate-y-[70%] max-w-[605px] ' +
                  (schoolsPage ? 'hidden lg:block' : 'hidden')
                }
              />

              <img
                src="/assets/images/other/schools_tools_mobile.png"
                alt=""
                className={
                  'absolute top-[50%] left-[50%] ' +
                  '-translate-x-[50%] -translate-y-[50%] max-w-[300px] ' +
                  (schoolsPage ? 'block lg:hidden' : 'hidden')
                }
              />
            </div>

            <div
              className="flex w-[100vw] px-3 scrollbar-thumb-rounded-full scrollbar-track-rounded-full
                scrollbar-thumb-bg-700/40 scrollbar-track-transparent scrollbar-thin
                overflow-x-auto mt-10 lg:mt-0"
            >
              <div className="flex mx-auto">
                {tools?.map((el, i) => {
                  return (
                    <ToolCard
                      key={`tool-${el.id}`}
                      tool={el}
                      activeToolHandler={setActiveTool}
                      showMoreClickHandler={setOpenModal}
                    />
                  );
                })}

                <ToolModal modalCloseHandler={setOpenModal} openModal={openModal} tool={activeTool as Tool} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
