import { Button, Footer, TextInput } from 'flowbite-react';
import { BsFacebook, BsInstagram, BsLinkedin } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';
import { ScheduleMeetingButton } from '../../atoms/schedule-meeting-button/schedule-meeting-button';
import { LanguagePicker } from '../../molecules/language-picker/language-picker';
import { useTranslation } from 'react-i18next';

export const CustomFooter = () => {
  const { t } = useTranslation(['translation', 'routes']);

  return (
    <Footer
      id="footer"
      container
      className="relative z-10 px-4 py-4 mt-24 rounded-b-none rounded-t-card bg-primary-500 font-body lg:px-16 lg:py-7"
    >
      <div className="m-auto max-w-[993px]">
        <ScheduleMeetingButton className="lg:w-[516px] lg:h-[71px]" />

        <div className="flex flex-col justify-between w-full px-4 mt-12 gap-x-14 lg:items-start">
          <div id="footer-top-half" className="flex flex-row w-full justify-evenly lg:flex-row">
            <div className="flex flex-col w-full gap-y-5 text-bg-500">
              <Link to={t('routes.main', { ns: 'routes' })}>
                <Footer.Brand
                  className="m-auto max-w-max lg:m-0"
                  alt="MyPolis Logo"
                  src="/assets/images/logos/logo_mypolis_white_slogan.png"
                />
              </Link>
              <span>{t('footer.description')}</span>
            </div>
            <div className="flex flex-row w-full mt-10 lg:mt-0 lg:pl-[40px]">
              <div className="flex flex-col items-start w-full m-auto gap-y-5 justify-evenly gap-x-8 lg:flex-row lg:gap-y-0">
                <div>
                  <Footer.LinkGroup col className="font-semibold">
                    <Link
                      className="text-lg focus:outline-none hover:underline"
                      to={t('routes.main', { ns: 'routes' })}
                    >
                      {t('common.mainPage')}
                    </Link>
                    <Link
                      className="text-lg focus:outline-none hover:underline"
                      to={t('routes.localAuthorities', { ns: 'routes' })}
                    >
                      {t('common.localAuthorities')}
                    </Link>
                    <Link
                      className="text-lg focus:outline-none hover:underline"
                      to={t('routes.schools', { ns: 'routes' })}
                    >
                      {t('common.schools')}
                    </Link>
                    <Link className="text-lg focus:outline-none hover:underline" to={t('routes.htp', { ns: 'routes' })}>
                      {t('common.htp')}
                    </Link>
                    <Link
                      className="text-lg focus:outline-none hover:underline"
                      to={t('routes.grants', { ns: 'routes' })}
                    >
                      {t('common.grants')}
                    </Link>
                  </Footer.LinkGroup>
                </div>
                <div className="flex flex-col gap-y-2">
                  <Footer.Title title={t('common.aboutUs')} className="mb-0 capitalize" />
                  <Footer.LinkGroup col className="flex flex-col indent-6 space-y-2 gap-y-0.5">
                    <Link
                      className="text-lg focus:outline-none hover:underline"
                      to={t('routes.history', { ns: 'routes' })}
                    >
                      {t('common.history')}
                    </Link>
                    <Link
                      className="text-lg focus:outline-none hover:underline"
                      to={t('routes.team', { ns: 'routes' })}
                    >
                      {t('common.team')}
                    </Link>
                    <Link
                      className="text-lg focus:outline-none hover:underline"
                      to={t('routes.tools', { ns: 'routes' })}
                    >
                      {t('common.tools')}
                    </Link>
                    <Link
                      className="text-lg focus:outline-none hover:underline"
                      to={t('routes.impact', { ns: 'routes' })}
                    >
                      {t('common.impact')}
                    </Link>
                    {/* <Link className="hidden text-lg focus:outline-none hover:underline" to={t('routes.jobs', { ns: 'routes' })}>
                      {t('common.jobs')}
                    </Link>
                    <Link className="hidden text-lg focus:outline-none hover:underline" to={t('routes.news', { ns: 'routes' })}>
                      {t('common.news')}
                    </Link> */}
                  </Footer.LinkGroup>
                </div>
              </div>
            </div>
          </div>

          <div
            id="footer-bottom-half"
            className="flex flex-col items-center justify-center w-full mt-12 lg:flex-row lg:mt-0"
          >
            <div className="flex flex-col-reverse w-full text-bg-500 lg:flex-col">
              <LanguagePicker />
            </div>

            <div className="flex flex-col hidden w-full">
              <span className="text-lg font-title text-bg-500">Assina a nossa Newsletter</span>
              <form action="" className="mt-2">
                <div className="w-full">
                  <TextInput id="email" placeholder="Coloque aqui o teu e-mail" required type="text" color="input" />
                </div>
              </form>
              <Button pill color="bg" className="self-end w-20 mt-3 font-semibold">
                Enviar
              </Button>
            </div>
          </div>
        </div>

        <div className="flex flex-col px-4 mt-6 mb-18">
          <span className="font-title text-[18px] mb-6 text-bg-500 lg:mb-0">{t('footer.financing')}</span>
          <div className="flex flex-col w-full mt-6 gap-y-6">
            <div className="flex flex-wrap w-full gap-x-6 gap-y-6">
              <Link to="https://algarve2020.pt" target="_blank">
                <img src="/assets/images/finance/cresc_algarve.png" alt="CRESC Algarve" className="max-h-[45px]" />
              </Link>
              <Link to="https://lisboa.portugal2020.pt/" target="_blank">
                <img src="/assets/images/finance/lisboa2020.png" alt="Lisboa 2020" className="max-h-[45px]" />
              </Link>
              <Link to="https://poise.portugal2020.pt/" target="_blank">
                <img src="/assets/images/finance/poise.png" alt="POISE" className="max-h-[45px]" />
              </Link>
              <Link to="https://portugal2020.pt" target="_blank">
                <img src="/assets/images/finance/portugal2020.png" alt="Portugal 2020" className="max-h-[45px]" />
              </Link>
              <Link to="https://european-union.europa.eu/" target="_blank">
                <img src="/assets/images/finance/uniao_europeia.png" alt="União Europeia" className="max-h-[45px]" />
              </Link>
              <Link to="https://european-union.europa.eu/" target="_blank">
                <img
                  src="/assets/images/finance/fbb-black.png"
                  alt="Fundação Bissaya Barreto"
                  className="max-h-[45px] invert"
                />
              </Link>
            </div>

            <div className="flex flex-wrap w-full gap-x-6 gap-y-6">
              <Link to="https://inovacaosocial.portugal2020.pt/" target="_blank">
                <img src="/assets/images/finance/pis.png" alt="Portugal Inovação Social" className="max-h-[45px]" />
              </Link>
              <Link to="https://casadoimpacto.scml.pt/open-call/investimento/" target="_blank">
                <img src="/assets/images/finance/maisplus.png" alt="+ PLUS" className="max-h-[45px]" />
              </Link>
              <Link to="https://casadoimpacto.scml.pt/" target="_blank">
                <img src="/assets/images/finance/ci.png" alt="Casa do Impacto" className="max-h-[45px]" />
              </Link>
              <Link to="https://european-union.europa.eu/" target="_blank">
                <img
                  src="/assets/images/finance/gulbenkian.png"
                  alt="Fundação Calouste Gulbenkian"
                  className="max-h-[45px] invert"
                />
              </Link>
              <Link to="https://european-union.europa.eu/" target="_blank">
                <img
                  src="/assets/images/finance/active-citizens-fund.png"
                  alt="Active Citizens Fund"
                  className="max-h-[45px] invert"
                />
              </Link>
            </div>
          </div>
        </div>

        <div className="flex w-full px-4 mt-6">
          <div className="text-bg-500">
            CASA DO IMPACTO
            <br />
            Tv. de São Pedro 8, 1200-432 Lisboa
            <br />
            <a href="mailto:hello@mypolis.eu">hello@mypolis.eu</a>
          </div>
        </div>

        <div className="flex flex-col items-center w-full px-4 mt-7 lg:mt-6 lg:block">
          <span className="font-title text-[18px] mb-6 text-bg-500 lg:mb-0">{t('footer.followUs')}</span>
          <div
            id="socials"
            className="flex items-start flex-grow w-auto m-auto text-white max-w-max h-11 gap-x-4 lg:h-12 lg:m-0 lg:mt-2"
          >
            <Footer.Icon href="https://www.facebook.com/mypolis.eu" target="_blank" icon={BsFacebook} />
            <Footer.Icon href="https://www.instagram.com/mypolis.eu" target="_blank" icon={BsInstagram} />
            <Footer.Icon href="https://www.linkedin.com/company/mypolis" target="_blank" icon={BsLinkedin} />
          </div>
        </div>
        <div className="flex flex-col items-center w-full mt-16">
          <div className="flex flex-row flex-wrap items-center justify-center w-full mb-1 text-sm text-bg-500">
            <Footer.Link
              href="https://community.mypolis.eu/terms/privacy"
              target="_blank"
              className="list-none text-[10px] lg:text-sm"
            >
              {t('footer.privacy')}
            </Footer.Link>
            <li className="mx-3 list-none">•</li>
            <Footer.Link
              href="https://community.mypolis.eu/terms/manifest"
              target="_blank"
              className="list-none text-[10px] lg:text-sm"
            >
              {t('footer.manifest')}
            </Footer.Link>
            <li className="mx-3 list-none">•</li>
            <Footer.Link
              href="https://community.mypolis.eu/terms/terms"
              target="_blank"
              className="list-none text-[10px] lg:text-sm"
            >
              {t('footer.tos')}
            </Footer.Link>
            <li className="mx-3 list-none">•</li>
            <Footer.Link
              href={t('routes.cpp', { ns: 'routes' })}
              target="_blank"
              className="list-none text-[10px] lg:text-sm"
            >
              {t('footer.cpp')}
            </Footer.Link>
          </div>
          <Footer.Copyright by="MyPolis" href="#" year={new Date().getFullYear()} />
        </div>
      </div>
    </Footer>
  );
};
