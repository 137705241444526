import { useTranslation } from 'react-i18next';
import TestimonyCard from '../../molecules/testimony-card/testimony-card';
import { Testimony } from '../../../api';
import { chunkify } from '../../../utilities';

export const Feedback = ({ schoolsPage = false }) => {
  const { t } = useTranslation(['translation', 'testimonies']);

  const testimonies = t('testimonies', { ns: 'testimonies', returnObjects: true }) as Testimony[];

  return (
    <>
      {testimonies && typeof testimonies === 'object' && (
        <div id="user-feedback" className={`flex flex-col items-center mt-20 relative z-10`}>
          <div className="relative z-0 flex">
            <img
              id="rocketship"
              src="/assets/images/other/rocketship.png"
              alt=""
              className={
                'absolute -left-12 -top-18 max-w-[180px] lg:-left-64 lg:-top-16 lg:max-w-[280px] -rotate-[35deg] ' +
                (schoolsPage ? 'hidden' : '')
              }
            />
            <span className="mx-auto text-2.5xl font-semibold font-title text-secondary-500 text-center px-20 lg:px-0">
              {t('testimonies.title')}
            </span>
            <img
              src="/assets/images/other/white_star.svg"
              alt=""
              className={
                'absolute ' + (schoolsPage ? '-left-40 -top-12' : 'right-12 bottom-12 lg:-right-24 lg:-bottom-5')
              }
            />

            <img
              src="/assets/images/other/red_baloon_lg.png"
              alt=""
              className={'absolute -right-56 bottom-2 max-w-[158px] ' + (schoolsPage ? '' : 'hidden')}
            />
          </div>

          <div
            className="grid grid-cols-1 mx-auto max-w-[300px] gap-8 mt-16 z-10
                lg:max-w-fit lg:grid-flow-row lg:grid-cols-3"
          >
            {chunkify(testimonies, 3).map((chunk, i) => (
              <div key={`testimony-container-${i}`} className="flex flex-col gap-y-8">
                {chunk.map((testimony, j) => (
                  <TestimonyCard key={`testimony-${j}`} testimony={testimony} />
                ))}
              </div>
            ))}

            {/* <div>
                <TestimonyCard testimony={{}}></TestimonyCard>
              </div>
              <div>
                <TestimonyCard testimony={{}}></TestimonyCard>
              </div>
            </div>
            <div className="flex flex-col gap-y-8">
              <div>
                <TestimonyCard testimony={{}}></TestimonyCard>
              </div>
              <div>
                <TestimonyCard testimony={{}}></TestimonyCard>
              </div>
              <div>
                <TestimonyCard testimony={{}}></TestimonyCard>
              </div>
            </div>
            <div className="flex flex-col gap-y-8">
              <div>
                <TestimonyCard testimony={{}}></TestimonyCard>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};
