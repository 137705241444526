import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { Button } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import toast from 'react-simple-toasts';
import { Category } from '../../../utilities/enums';
import { FormHiddenInput } from '../../atoms/form-fields/form-hidden-input';
import { FormTextInput } from '../../atoms/form-fields/form-text-input';
import { FormTextArea } from '../../atoms/form-fields/form-textarea';
import { SuccessToast } from '../../atoms/form-toasts/sucess-toast';
import { ParticipateFormInputs, participateFormSchema } from './schemas';

export const ParticipateForm = () => {
  const [hasErrors, setHasErrors] = useState(false);
  const { t } = useTranslation();

  const methods = useForm<ParticipateFormInputs>({
    resolver: yupResolver(participateFormSchema),
    defaultValues: {
      'g-recaptcha-response': undefined,
      email: '',
      fullName: '',
      message: '',
      territoryOrCommunity: '',
      messageType: Category.PARTICIPATE,
    },
  });

  useEffect(() => {
    methods.setValue('messageType', Category.PARTICIPATE);
    setHasErrors(false);
  }, [methods]);

  const { isSubmitting } = useFormState<ParticipateFormInputs>({ control: methods.control });

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const onError: SubmitErrorHandler<ParticipateFormInputs> = (errors, e) => {
    setHasErrors(true);
  };

  const onSubmit: SubmitHandler<ParticipateFormInputs> = async (data) => {
    try {
      return await recaptchaRef.current
        ?.executeAsync()
        .then(async (token) => {
          data['g-recaptcha-response'] = token ?? undefined;

          return await axios
            .post(process.env.REACT_APP_EMAIL_LAMBDA_URL as string, data)
            .then((result) => {
              toast(<SuccessToast />);
              return;
            })
            .catch((error) => {
              // toast(<ErrorToast />);
              console.error(error);
            });
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.error(error);
    } finally {
      recaptchaRef.current?.reset();
    }
  };

  return (
    <div className={'flex flex-col items-center w-full relative lg:mt-24'}>
      <img
        className="hidden absolute w-[718px] -top-13 lg:block"
        src="/assets/images/htp/htp_form_header_lg.png"
        alt="Foguetão e balões de fala"
      />
      <div className="flex flex-col items-center justify-center w-full px-4 text-center">
        <img
          className="w-full lg:hidden"
          src="/assets/images/htp/htp_form_header.png"
          alt="Foguetão e balões de fala"
        />
        <span className="font-title text-2xl font-semibold lg:text-2.5xl" dangerouslySetInnerHTML={{ __html: t("forms.htp.title") }}>
        </span>
      </div>
      <div className="relative flex w-full px-10 mt-12 lg:justify-center">
        <img className="absolute -right-3 -top-18 w-36 lg:hidden" src="/assets/images/htp/htp_pencil.png" alt="Lápis" />
        <div className="text-base font-normal font-title text-primary-500 -rotate-5 lg:flex lg:justify-center lg:-mt-4 max-w-[260px] lg:max-w-[410px]">
          {t("forms.htp.subTitle")}
        </div>
      </div>
      <div className="relative flex flex-row items-start justify-center w-full mb-2 gap-x-10 forms lg:mt-18">
        <div className="flex w-full px-6 lg:px-0">
          <div className="flex flex-col justify-end w-full gap-y-4">
            <FormProvider {...methods}>
              <form
                id="contact-form"
                onSubmit={methods.handleSubmit(onSubmit, onError)}
                className={`flex flex-col z-10 max-w-[993px] gap-y-2 lg:px-[90px]`}
              >
                <span className="mt-12 mb-4 lg:mt-0">{t("forms.htp.text")}.</span>
                <FormHiddenInput name="messageType" id="message-type" />
                <div className="flex flex-col gap-x-4 lg:flex-row">
                  <div className={`flex flex-col w-full lg:w-2/5 ${hasErrors ? 'gap-y-2' : 'gap-y-4'}`}>
                    <div className="w-full">
                      <FormTextInput name="fullName" id="full-name" placeholder={t("forms.fields.name")} />
                    </div>
                    <div className="w-full">
                      <FormTextInput name="email" id="email" placeholder={t("forms.fields.email")} />
                    </div>
                    <div className="w-full">
                      <FormTextInput
                        name="territoryOrCommunity"
                        id="territoryOrCommunity"
                        placeholder={t("forms.fields.territory")}
                      />
                    </div>
                  </div>
                  <div className="flex w-full pt-4 lg:w-3/5 lg:pt-0">
                    <div className="flex flex-col w-full" id="textarea">
                      <FormTextArea
                        name="message"
                        id="message"
                        placeholder={t("forms.fields.message")}
                        rows={10}
                        className="flex-grow"
                      />
                    </div>
                  </div>
                </div>
                {createPortal(
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY as string}
                    size="invisible"
                    ref={recaptchaRef}
                  />,
                  document.body
                )}
                <div className="flex w-full">
                  <Button pill color="primary" type="submit" className="w-32 ml-auto" isProcessing={isSubmitting}>
                  {t("forms.sendButton")}
                  </Button>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
};
