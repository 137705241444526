import { useTranslation } from 'react-i18next';
import { ImpactDisplayer } from '../../molecules/impact-displayer/impact-displayer';
import './schools-impact.scss';

export const SchoolsImpact = () => {
  const { t } = useTranslation();

  const impactList1: { highlight: string, text: string, image: string }[] = t("schools.metrics.first.items", { returnObjects: true })
  const impactList2: { highlight: string, text: string, image: string }[] = t("schools.metrics.second.items", { returnObjects: true })

  return (
    <div className="flex flex-col items-center mt-40 impact">
      <div className="flex">
        <span className="text-2xl lg:text-2.5xl font-semibold font-title text-secondary-500 text-center px-8 lg:px-0">
          {t("schools.metrics.first.title1")}&nbsp;
          <br className="hidden lg:block" />{t("schools.metrics.first.title2")}
        </span>
      </div>

      <div className="flex flex-wrap justify-center mt-8 px-18">
        {impactList1 && typeof impactList1 === "object" && impactList1.map((metric, i) =>
          <ImpactDisplayer
            key={i}
            imgSrc={metric.image}
            title={metric.highlight}
            description={metric.text}
          ></ImpactDisplayer>
        )}
      </div>

      <div className="flex flex-col mt-5">
        <span className="px-4 text-sm text-center lg:px-0">
          {t("schools.metrics.first.explainer1")}
          <br />
          <span className="text-xs text-center">
            {t("schools.metrics.first.explainer2")}
          </span>
        </span>
      </div>

      <div className="flex mt-20">
        <span className="text-2xl lg:text-2.5xl font-semibold font-title text-secondary-500 text-center px-8 lg:px-0">
          {t("schools.metrics.second.title1")}
          <br />
          {t("schools.metrics.second.title2")}
        </span>
      </div>

      <div className="flex flex-wrap justify-center mt-8 px-18">
        {impactList2 && typeof impactList2 === "object" && impactList2.map((metric, i) =>
          <ImpactDisplayer
            key={i}
            imgSrc={metric.image}
            title={metric.highlight}
            description={metric.text}
          ></ImpactDisplayer>
        )}
      </div>

      <div className="flex flex-col mt-5">
        <span className="px-4 text-sm text-center lg:px-0">
          {t("schools.metrics.second.explainer1")}
          <br />
          <span className="text-xs text-center">
            {t("schools.metrics.second.explainer2")}
          </span>
        </span>
      </div>
    </div>
  );
};
