import { Button } from "flowbite-react";
import { Link } from "react-router-dom";
import "./hero-schools.scss";
import { useContext } from "react";
import { AppContext } from "../../../contexts/app/app-context";
import { useTranslation } from "react-i18next";

export const HeroSchools = ({ className }: any) => {
  const { fireAnimations } = useContext(AppContext);
  const { t } = useTranslation(undefined, { keyPrefix: "schools.hero" })

  return (
    <div
      className={
        className +
        " hero-schools gap-x-2 flex flex-col lg:flex-row justify-around w-full pt-10 -mb-12 lg:-mb-24"
      }
    >
      <div className="flex flex-col items-center justify-around w-full lg:w-1/2 gap-y-10">
        <div
          className="flex flex-col justify-center w-full px-4 text-3xl lg:items-start lg:text-5xl text-secondary-500 font-title gap-y-2 lg:-rotate-5"
        >
          <div className="flex flex-col mx-auto w-fit">
            <span className="px-4 w-fit">{t("transform")}</span>
            <span className="px-4 py-2 mt-2 text-white bg-red-500 rounded-full lg:bg-primary-500 w-fit">
              {t("classrooms")}
            </span>
            <span className="px-4 mt-2 w-fit">{t("academies")}</span>
            <div className="flex flex-row px-4 mt-2">
              <span className="py-2">{t("participation1")}&nbsp;</span>
              <span className="px-2 py-2 text-white lg:px-4 w-max bg-primary-500">
                {t("participation2")}
              </span>
            </div>
          </div>

          <img
            className="block w-full mx-auto mt-8 lg:hidden"
            src="/assets/images/other/phone_mobile.png"
            alt="Loud Speaker"
          />

          <div className="px-4 mt-2 text-base lg:text-xl text-primary-900/50">
            {t("subText1")}
          </div>
        </div>
        <div className="relative flex flex-col w-full">
          <div className="w-full px-4">
            <Button
              pill
              as={Link}
              target="_blank"
              className="w-full h-12"
              color="secondary"
              to="https://escolas.mypolis.eu"
            >
              <span className="text-xl font-semibold">
              {t("loginTeacherWebsite")}
              </span>
            </Button>
          </div>

          <img
            className={`rotate-180 w-1/4 absolute left-32 ${fireAnimations ? "animate" : ""
              }`}
            id="arrow-down-twirl"
            src="/assets/images/arrows/arrow_down_twirl.png"
            alt="Arrow down"
          />
        </div>
      </div>

      <div className="hidden w-full lg:block lg:w-1/2">
        <img
          className="w-4/5 mx-auto mt-8"
          src="/assets/images/other/phone.png"
          alt="Loud Speaker"
        />
      </div>
    </div>
  );
};
