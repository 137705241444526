import { Button } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { Customer, Tool } from '../../../api';
import './customer-map-flip-card.scss';

type CustomerMapPopupProps = {
  customer: Required<Customer>;
  visible?: boolean;
  className: string;
  modalOpenHandler: Function;
  activeToolHandler: Function;
};

export const CustomerMapPopup = ({
  customer,
  visible = true,
  className = '',
  modalOpenHandler,
  activeToolHandler,
}: CustomerMapPopupProps) => {
  const { t } = useTranslation(['translation', 'tools'], { useSuspense: false });
  const tools = t('tools', { ns: 'tools', returnObjects: true }) as Tool[];
  const flipCard = (event: any) => {
    event.currentTarget.closest('.flippable-card').classList.add('flipped');
    event.currentTarget.blur();
  };

  const unflipCard = (event: any) => {
    event.currentTarget.closest('.flippable-card').classList.remove('flipped');
    event.currentTarget.blur();
  };

  const handleToolClick = (tool: Tool) => {
    modalOpenHandler();
    activeToolHandler(tool);
  };

  const metrics = [];

  for (let i = 0; i < 2; i++) {
    metrics.push(customer.metrics[i]);
  }

  return (
    customer && (
      <div
        className={`absolute h-[430px] w-[270px] left-0 top-1/2 -translate-y-1/2 block lg:block perspective ${
          visible ? '' : 'hidden'
        } ${className}`}
      >
        <div className="relative w-full h-full flippable-card rounded-card">
          <div className="flex flex-col card-front absolute w-full h-full rounded-card overflow-hidden p-7 pb-[4.7rem] pt-4 gap-4">
            <img
              className="customer-logo max-h-[68px] mx-auto -mb-2"
              src={customer.logo ?? ''}
              alt={customer.name ?? ''}
            />
            <div className="text-xl font-semibold font-title">{customer.shortName ?? ''}</div>
            <div className="max-h-[124px] text-sm leading-[18px] pointer-events-auto">{customer.description ?? ''}</div>
            <div className="flex flex-row mt-auto">
              {metrics.map((metric, i, metrics) => (
                <div
                  key={`${customer.slug}-${metric.name}-metric`}
                  className={`customer-metric flex flex-col w-1/${metrics.length}`}
                >
                  <span className="text-xl font-semibold text-primary-500 font-title">{metric.data}</span>
                  <span className="[word-spacing:100px] text-base font-medium font-body leading-18 ">
                    {metric.description}
                  </span>
                </div>
              ))}
            </div>
            <Button
              pill
              id="flip"
              color="white"
              className={`absolute flex flex-col justify-center w-12 h-12 bg-white border-solid rounded-full cursor-pointer right-5 bottom-5 shadow-button border-primary-200 ${
                customer.tools.length === 0 ? 'hidden' : ''
              }`}
              onClick={flipCard}
            >
              <svg
                className="w-8 h-8 m-auto text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 18"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 1v16M1 9h16"
                />
              </svg>
            </Button>
          </div>
          <div className="absolute w-full h-full overflow-hidden text-sm bg-white border-0 card-back rounded-card p-7">
            <div className="flex flex-col gap-5">
              <span className="text-base">
                {t('common.map.tools', { customer: customer.shortName ?? t('common.thisCustomer') })}
              </span>
              <div className="flex flex-col gap-5">
                {
                  <>
                    {tools &&
                      typeof tools === 'object' &&
                      tools
                        .filter((tool) => customer.tools.includes(tool.id))
                        .map((tool: Tool) => (
                          <span
                            key={`${customer.slug}-${tool.id}-tool`}
                            className="py-2 text-center align-middle bg-gray-100 rounded-full cursor-pointer customer-tool hover:bg-gray-200"
                            onClick={() => handleToolClick(tool)}
                          >
                            {tool.title}
                          </span>
                        ))}
                  </>
                }
              </div>
            </div>
            <Button
              pill
              id="flip"
              color="white"
              className="absolute flex flex-col justify-center w-12 h-12 bg-white rounded-full cursor-pointer right-5 bottom-5 shadow-button"
              onClick={unflipCard}
            >
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </Button>
          </div>
        </div>
      </div>
    )
  );
};
