import { Spinner } from 'flowbite-react';
import { FeatureCollection } from 'geojson';
import mapboxgl, { AttributionControl, MapboxGeoJSONFeature, NavigationControl } from 'mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { MutableRefObject, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Customer, Tool } from '../../../api';
import { AppContext } from '../../../contexts/app/app-context';
import { convertObjectToGeoJson } from '../../../utilities';
import { CustomerMapPopup } from '../../molecules/customer-map-flip-card/customer-map-flip-card';
import { ToolModal } from '../../molecules/tool-modal/tool-modal';
import './customer-map.scss';

type CustomerMapProps = {
  className?: string | undefined;
  title?: string;
  showTitleIcons?: boolean;
};

type GeoJsonFeature = {
  geometry: {
    type: string;
    coordinates: [number, number];
  };
  id: string | number;
  layer: {
    id: string;
    type: string;
    source: string;
    filter: Array<any>;
    layout: object;
    paint: object;
  };
  properties: {
    cluster: boolean;
    cluster_id: number;
    point_count: number;
    point_count_abbreviated: number;
  };
  source: string;
  state: object;
  type: string;
};

type CustomGeoJSONType = MapboxGeoJSONFeature & {
  toJSON: () => GeoJsonFeature;
};

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN as string;

export const CustomerMap = ({ className, title, showTitleIcons = true }: CustomerMapProps) => {
  const mapContainer: any = useRef(null);
  const map: MutableRefObject<mapboxgl.Map> = useRef<mapboxgl.Map>(null) as MutableRefObject<mapboxgl.Map>;

  const lat = 39.69484,
    lng = -8.13031,
    zoom = 5.8;
  const [popupVisible, setPopupVisible] = useState(false);
  const [activeCustomer, setActiveCustomer] = useState<Required<Customer> | undefined>(undefined);
  const [previousClickedFeature, _setPreviousClickedFeature] = useState<number | undefined>();
  const [hidden, setHidden] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [customersGeoJson, setCustomersGeoJson] = useState<FeatureCollection | undefined>();
  const [openModal, setOpenModal] = useState(false);
  const [activeTool, setActiveTool] = useState<Tool | undefined>();
  const previousClickedFeatureRef = useRef(previousClickedFeature);
  const setPreviousClickedFeature = useCallback((feature: number) => {
    previousClickedFeatureRef.current = feature;
    _setPreviousClickedFeature(feature);
  }, []);
  const { t, i18n } = useTranslation(['translation', 'customers']);
  title = title ?? t('common.map.title');

  const { setFireAnimations } = useContext(AppContext);

  const fetchCommunities = useCallback(() => {
    return new Promise(() => {
      let customers: Customer[] = t('customers', { ns: 'customers', returnObjects: true });
      customers = customers.filter((c) => c.visibleInMap);
      if (typeof customers === 'object') {
        setCustomersGeoJson(convertObjectToGeoJson(customers) as FeatureCollection);
        setIsLoading(false);
      } else {
      }
    });
  }, [t, setCustomersGeoJson]);

  const navigation = useMemo(
    () =>
      new NavigationControl({
        showCompass: false,
        showZoom: true,
      }),
    []
  );

  const attribution = useMemo(() => new AttributionControl(), []);

  const waitForStylesToLoad = (callBack: Function) => {
    const t = setInterval(() => {
      if (map.current.isStyleLoaded()) {
        clearInterval(t);
        callBack();
      }
    }, 100);
  };

  const modalCloseHandler = () => {
    setOpenModal(false);
  };

  const modalOpenHandler = () => {
    setOpenModal(true);
  };

  const initializeMap = useCallback(() => {
    try {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v12',
        center: [lng, lat],
        zoom: zoom,
        attributionControl: false,
        cooperativeGestures: true,
        antialias: true,
        worldview: 'PT',
      });

      map.current.addControl(navigation, 'bottom-right');
      map.current.addControl(attribution, 'bottom-left');

      map.current.loadImage('/assets/images/other/unclustered-point.png', (error, image) => {
        if (error) {
          setHidden(true);
          console.error(error);
          return;
        }

        map.current.addImage('unclustered-point', image as ImageBitmap);
      });

      map.current.loadImage('/assets/images/other/clustered-point.png', (error, image) => {
        if (error) {
          setHidden(true);
          console.error(error);
          return;
        }

        map.current.addImage('clustered-point', image as ImageBitmap);
      });

      map.current.loadImage('/assets/images/other/unclustered-selected-point.png', (error, image) => {
        if (error) {
          setHidden(true);
          console.error(error);
          return;
        }

        map.current.addImage('unclustered-selected-point', image as ImageBitmap);
      });
      setHidden(false);
    } catch (e) {
      console.error(e);
      setHidden(true);
    }
  }, [setHidden, attribution, lat, lng, navigation]);

  function unloadCustomLayers() {
    if (map.current) {
      const customerSource = map.current.getSource('customers');

      if (customerSource) {
        map.current.removeLayer('clustered-customers');
        map.current.removeLayer('unclustered-customers');
        map.current.removeLayer('unclustered-selected-customers');
        map.current.removeSource('customers');
      }
    }
  }

  const loadCustomerLayer = useCallback(() => {
    try {
      if (!map.current.isStyleLoaded()) return;

      if (!map.current.getSource('customers') && customersGeoJson) {
        map.current.addSource('customers', {
          type: 'geojson',
          data: customersGeoJson,
          cluster: true,
          tolerance: 5,
          clusterMaxZoom: 14, // Max zoom to cluster points on
          clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
        });

        map.current.addLayer({
          id: 'unclustered-customers',
          type: 'symbol',
          source: 'customers',
          filter: ['!', ['has', 'point_count']],
          layout: {
            'icon-allow-overlap': true,
            'icon-anchor': 'bottom',
            // "icon-image": [
            //   "case",
            //   ["boolean", ["feature-state", "clicked"], true],
            //   ["image", "unclustered-selected-point"],
            //   ["image", "unclustered-point"],
            // ],
            'icon-image': 'unclustered-point',
            'icon-size': 0.5,
          },
          paint: {
            'icon-opacity': ['case', ['boolean', ['feature-state', 'clicked'], false], 0, 1],
          },
        });

        map.current.addLayer({
          id: 'unclustered-selected-customers',
          type: 'symbol',
          source: 'customers',
          filter: ['!', ['has', 'point_count']],
          layout: {
            'icon-allow-overlap': true,
            'icon-anchor': 'bottom',
            'icon-image': 'unclustered-selected-point',
            'icon-size': 0.55,
          },
          paint: {
            'icon-opacity': ['case', ['boolean', ['feature-state', 'clicked'], false], 1, 0],
          },
        });

        map.current.addLayer({
          id: 'clustered-customers',
          type: 'symbol',
          source: 'customers',
          filter: ['has', 'point_count'],
          layout: {
            'icon-allow-overlap': true,
            'icon-anchor': 'bottom',
            'icon-image': 'clustered-point',
            'icon-size': 0.55,
            'text-field': ['get', 'point_count_abbreviated'],
            'text-font': ['Arial Unicode MS Bold'],
            'text-size': 14,
            'text-anchor': 'bottom',
            'text-offset': [0, -2.4],
          },
        });
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setHidden(true);
    }
  }, [customersGeoJson, setHidden]);

  const unclusteredLayerClick = useCallback(
    (e: any) => {
      if (e.features === undefined) return;
      // Copy coordinates array.
      const coordinates = e.features[0].geometry.coordinates;
      const customer = JSON.parse(e.features[0].properties.data);
      const clickedFeature = e.features[0].id;

      if (previousClickedFeatureRef?.current && previousClickedFeatureRef.current !== 0) {
        // Revert the style for the previously clicked feature
        map.current.setFeatureState(
          {
            source: 'customers',
            id: previousClickedFeatureRef.current,
          },
          { clicked: false }
        );
      }

      map.current.setFeatureState({ source: 'customers', id: clickedFeature }, { clicked: true });

      map.current.flyTo({
        speed: 1,
        center: [coordinates[0] - 2 / map.current.getZoom(), coordinates[1]],
      });

      setActiveCustomer(undefined);

      // to ensure the customer update
      setTimeout(() => {
        setActiveCustomer(customer);
      }, 1);

      setPopupVisible(true);
      setPreviousClickedFeature(clickedFeature);
    },
    [setActiveCustomer, setPopupVisible, setPreviousClickedFeature]
  );

  const clusteredLayerClick = (e: any) => {
    const bbox: [[number, number], [number, number]] = [
      [e.point.x - 5, e.point.y - 5],
      [e.point.x + 5, e.point.y + 5],
    ];
    // Find features intersecting the bounding box.
    const clustered = map.current.queryRenderedFeatures(bbox, {
      layers: ['clustered-customers'],
    });

    if (clustered.length > 0) {
      const selectedCluster = (clustered[0] as CustomGeoJSONType).toJSON();
      map.current.flyTo({
        zoom: map.current.getZoom() + 1,
        center: [
          selectedCluster.geometry.coordinates[0] - 2 / (map.current.getZoom() + 1),
          selectedCluster.geometry.coordinates[1],
        ],
      });
    }
  };

  const mapOnLoadHandler = useCallback(() => {
    const roadLayers = map.current.getStyle().layers.filter((layer: any) => layer.id.includes('road'));

    for (const layer of roadLayers) {
      map.current.removeLayer(layer.id);
    }

    // map.current.setFeatureState({ source: "customers" }, { clicked: false });

    map.current.on('click', () => {
      setPopupVisible(false);
      setActiveCustomer(undefined);

      if (previousClickedFeatureRef?.current && previousClickedFeatureRef.current !== 0) {
        // Revert the style for the previously clicked feature
        map.current.setFeatureState(
          {
            source: 'customers',
            id: previousClickedFeatureRef.current,
          },
          { clicked: false }
        );
      }
    });

    map.current.on('click', 'clustered-customers', (e: any) => clusteredLayerClick(e));

    map.current.on('click', 'unclustered-customers', (e: any) => unclusteredLayerClick(e));
  }, [unclusteredLayerClick]);

  useEffect(() => {
    fetchCommunities()
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setHidden(true);
      });

    if (!map.current) {
      initializeMap();
    } // initialize map only once

    map.current.on('load', () => mapOnLoadHandler());

    return () => {
      if (map.current) {
        map.current.off('load', () => mapOnLoadHandler());
        map.current.off('click', () => {
          setPopupVisible(false);
          setActiveCustomer(undefined);

          if (previousClickedFeatureRef?.current && previousClickedFeatureRef.current !== 0) {
            // Revert the style for the previously clicked feature
            map.current.setFeatureState(
              {
                source: 'customers',
                id: previousClickedFeatureRef.current,
              },
              { clicked: false }
            );
          }
        });
        map.current.off('click', 'clustered-customers', (e: any) => clusteredLayerClick(e));
        map.current.off('click', 'unclustered-customers', (e: any) => unclusteredLayerClick(e));
      }
    };
  }, [fetchCommunities, mapOnLoadHandler, initializeMap, unclusteredLayerClick]);

  useEffect(() => {
    if (customersGeoJson) {
      waitForStylesToLoad(() => {
        loadCustomerLayer();
      });
    }

    return () => {
      unloadCustomLayers();
    };
  }, [customersGeoJson, loadCustomerLayer]);

  useEffect(() => {
    console.log('isLoading: ', isLoading);
    if (!isLoading) setFireAnimations(true);
  }, [isLoading, setFireAnimations]);

  useEffect(() => {
    fetchCommunities()
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setHidden(true);
      });
  }, [i18n.language, fetchCommunities]);

  return (
    <div className={`mt-24 ${className ?? ''}  ${hidden ? 'hidden' : ''}`}>
      <div className="flex flex-col items-center w-full mb-12 gap-y-6">
        <span className="m-auto text-2.5xl font-semibold font-title text-center">{title}</span>
        <div className="relative flex flex-row">
          <img
            src="/assets/images/other/yellow_balloon.png"
            alt=""
            className={`${showTitleIcons ? '' : 'hidden'} absolute rotate-6 left-4 -top-36 lg:-left-16 lg:-top-24`}
          />
          <img
            src="/assets/images/other/white_star.svg"
            alt=""
            className={`${showTitleIcons ? '' : 'hidden'} absolute w-9 right-4 -top-24 lg:-right-[106px] lg:-top-10`}
          />
          <img
            src="/assets/images/lines/spiral.png"
            alt=""
            className={`${showTitleIcons ? '' : 'hidden'} hidden lg:block absolute w-36 -right-64 -top-32`}
          />
          <span className="px-6 m-auto text-lg text-center font-body lg:px-0">
            {t('common.map.subTitle', { territoryN: customersGeoJson?.features.length ?? 0 })}
          </span>
        </div>
      </div>
      <div className={`w-full h-[600px] relative px-6 customer-map ${hidden && 'hidden'}`}>
        <div
          className={`z-20 flex flex-col justify-center absolute h-full w-screen bg-black/20 left-1/2 -translate-x-1/2 ${
            isLoading ? '' : 'hidden'
          }`}
        >
          <div className="mx-auto w-fit">
            <Spinner size="3xl" color="primary" className="mx-auto"></Spinner>
          </div>
        </div>
        <div className="absolute z-10 w-screen h-full -translate-x-1/2 left-1/2">
          <div ref={mapContainer} className="relative w-full h-full map-container" />
        </div>
        {activeCustomer ? (
          <>
            <CustomerMapPopup
              className="z-20 ml-8"
              visible={popupVisible}
              customer={activeCustomer}
              modalOpenHandler={modalOpenHandler}
              activeToolHandler={(tool: Tool) => setActiveTool(tool)}
            />
            <ToolModal modalCloseHandler={modalCloseHandler} openModal={openModal} tool={activeTool as Tool} />
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
