import { useTranslation } from 'react-i18next';
import { NewsCard } from '../../molecules/news-card/news-card';

type NewsCardType = {
  newsURL: string;
  imageHeader?: string;
  imageHeaderSize?: string;
  title?: string;
  titleClassName?: string;
  content?: string;
  contentType?: 'video' | 'image';
  poster?: string;
  contentClassName?: string;
  className?: string;
};

const newsListDesktop: [Array<NewsCardType>, Array<NewsCardType>, Array<NewsCardType>] = [
  [
    {
      newsURL: 'https://www.rtp.pt/play/p10207/e613223/planeta-a',
      imageHeader: '/assets/images/news/rtp.png',
      imageHeaderSize: 'w-[165px] px-4',
      title: 'Planeta A',
      content: 'https://cdn.mypolis.eu/landing-page/news/planeta_a.mp4',
      contentType: 'video',
      poster: '/assets/images/news/planeta_a_thumb.jpg',
      contentClassName: 'w-full',
    },
    {
      newsURL: 'https://correiodoribatejo.pt/teatro-virginia-recebeu-assembleia-de-transformadores-sociais/',
      imageHeader: '/assets/images/news/c_ribatejo.png',
      imageHeaderSize: 'w-[225px] px-4',
      title:
        '"As propostas foram bem acolhidas pelos decisores políticos que a todos incentivaram a continuidade do trabalho desenvolvido."',
      contentClassName: 'w-full',
    },
    {
      newsURL:
        'https://expresso.pt/politica/2024-02-07-Pedagogia-pedagogia-e-a--nova--palavra-de-ordem-dos-50-anos-do-25-de-Abril-e-o-Chat-GPT-vai-participar-f9244072',
      imageHeader: '/assets/images/news/expresso.png',
      imageHeaderSize: 'w-[225px] px-4',
      className: 'ml-auto',
    },
    {
      newsURL:
        'https://www.publico.pt/2019/06/13/p3/noticia/mypolis-esta-app-quer-criar-uma-polis-para-os-millennials-1873747',
      imageHeader: '/assets/images/news/publico.png',
      imageHeaderSize: 'w-[120px]',
      className: 'ml-auto',
    },
  ],
  [
    {
      newsURL:
        'https://observador.pt/2018/05/22/mypolis-a-plataforma-que-quer-tornar-a-politica-mais-sexy-para-millennials/',
      imageHeader: '/assets/images/news/observador.png',
      imageHeaderSize: 'w-[225px] px-2',
      title: '"MyPolis,<br/>a plataforma que quer<br/>tornar a política mais<br/>sexy para millennials"',
      titleClassName: 'mb-2',
      className: 'w-full mx-auto',
    },
    {
      newsURL: 'https://ffms.pt/pt-pt/atualmentes/e-possivel-cativar-os-jovens-para-politica',
      imageHeader: '/assets/images/news/fundacao_mds.png',
      imageHeaderSize: 'w-[165px]',
      content: '/assets/images/news/fundacao_mds_image.png',
      contentType: 'image',
      contentClassName: 'w-full',
      className: 'w-full mx-auto',
    },
    {
      newsURL:
        'https://coimbracoolectiva.pt/historias/temas/miudos/mypolis-25-mil-jovens-ja-perceberam-que-podem-tratar-a-politica-por-tu/',
      imageHeader: '/assets/images/news/coimbra.png',
      imageHeaderSize: 'w-[95px] brightness-0',
      title: '"25 mil jovens já perceberam que podem tratar a política por tu"',
      titleClassName: 'mb-2',
      content: '/assets/images/news/coimbra_image.jpg',
      contentType: 'image',
      contentClassName: 'w-full',
      className: 'w-full mx-auto',
    },
  ],
  [
    {
      newsURL: 'https://visao.pt/exame/2020-01-15-startups-por-um-planeta-melhor/',
      imageHeader: '/assets/images/news/exame.png',
      imageHeaderSize: 'w-[125px]',
      content: '/assets/images/news/exame_image.png',
      contentType: 'image',
      contentClassName: 'w-[255px]',
    },
    {
      newsURL: 'https://www.barlavento.pt/mais/educacao/lagos-quer-promover-cidadania-ativa-dos-jovens-nas-escolas',
      imageHeader: '/assets/images/news/barlavento.png',
      imageHeaderSize: 'w-[225px] px-4 py-2',
      title: '"Executivo de Lagoa<br/>apresentou-se à<br/>comunidade escolar<br/>pelo projeto MyPolis"',
      titleClassName: 'mb-2',
      className: 'ml-0 mr-auto',
    },
    {
      newsURL:
        'https://regiao-sul.pt/sociedade/municipio-de-lagos-apoia-promocao-da-cidadania-ativa-nas-escolas-c-video/657106',
      imageHeader: '/assets/images/customers/logo_lagos.png',
      imageHeaderSize: 'w-[125px]',
      className: 'w-full',
    },
    {
      newsURL: 'https://escolas.aglousa.com/2023/05/31/assembleia-my-polis/',
      imageHeader: '/assets/images/news/lousa.png',
      imageHeaderSize: 'w-[105px]',
      className: 'mr-auto',
    },
  ],
];

const newsListMobile: Array<NewsCardType> = [
  {
    newsURL: 'https://www.rtp.pt/play/p10207/e613223/planeta-a',
    imageHeader: '/assets/images/news/rtp.png',
    imageHeaderSize: 'w-[165px] px-4',
    title: 'Planeta A',
    content: 'https://cdn.mypolis.eu/landing-page/news/planeta_a.mp4',
    contentType: 'video',
    poster: '/assets/images/news/planeta_a_thumb.jpg',
    contentClassName: 'w-full',
    className: 'w-full mx-auto',
  },
  {
    newsURL: 'https://correiodoribatejo.pt/teatro-virginia-recebeu-assembleia-de-transformadores-sociais/',
    imageHeader: '/assets/images/news/c_ribatejo.png',
    imageHeaderSize: 'w-[225px] px-4',
    title:
      '"As propostas foram bem acolhidas pelos decisores políticos que a todos incentivaram a continuidade do trabalho desenvolvido."',
    contentClassName: 'w-full',
    className: 'w-full mx-auto',
  },
  {
    newsURL:
      'https://expresso.pt/politica/2024-02-07-Pedagogia-pedagogia-e-a--nova--palavra-de-ordem-dos-50-anos-do-25-de-Abril-e-o-Chat-GPT-vai-participar-f9244072',
    imageHeader: '/assets/images/news/expresso.png',
    imageHeaderSize: 'w-[225px] px-4',
    className: 'w-full mx-auto',
  },
  {
    newsURL:
      'https://observador.pt/2018/05/22/mypolis-a-plataforma-que-quer-tornar-a-politica-mais-sexy-para-millennials/',
    imageHeader: '/assets/images/news/observador.png',
    imageHeaderSize: 'w-[225px] px-2',
    title: '"MyPolis,<br/>a plataforma que quer<br/>tornar a política mais<br/>sexy para millennials"',
    titleClassName: 'mb-2',
    contentClassName: 'w-full',
    className: 'w-full mx-auto',
  },
  {
    newsURL: 'https://ffms.pt/pt-pt/atualmentes/e-possivel-cativar-os-jovens-para-politica',
    imageHeader: '/assets/images/news/fundacao_mds.png',
    imageHeaderSize: 'w-[165px]',
    content: '/assets/images/news/fundacao_mds_image.png',
    contentType: 'image',
    contentClassName: 'w-full',
    className: 'w-full mx-auto',
  },
  {
    newsURL:
      'https://www.publico.pt/2019/06/13/p3/noticia/mypolis-esta-app-quer-criar-uma-polis-para-os-millennials-1873747',
    imageHeader: '/assets/images/news/publico.png',
    imageHeaderSize: 'w-[120px]',
    contentClassName: 'w-full',
    className: 'w-full mx-auto',
  },
  {
    newsURL:
      'https://coimbracoolectiva.pt/historias/temas/miudos/mypolis-25-mil-jovens-ja-perceberam-que-podem-tratar-a-politica-por-tu/',
    title: '"25 mil jovens já perceberam que podem tratar a política por tu"',
    titleClassName: 'mb-2',
    content: '/assets/images/news/coimbra_image.jpg',
    contentType: 'image',
    contentClassName: 'w-full',
    className: 'w-full mx-auto',
  },
  {
    newsURL: 'https://www.barlavento.pt/mais/educacao/lagos-quer-promover-cidadania-ativa-dos-jovens-nas-escolas',
    imageHeader: '/assets/images/news/barlavento.png',
    imageHeaderSize: 'w-[225px] px-4 py-2',
    title: '"Executivo de Lagoa<br/>apresentou-se à<br/>comunidade escolar<br/>pelo projeto MyPolis"',
    titleClassName: 'mb-2',
    contentClassName: 'w-full',
    className: 'w-full mx-auto',
  },
  {
    newsURL: 'https://visao.pt/exame/2020-01-15-startups-por-um-planeta-melhor/',
    imageHeader: '/assets/images/news/exame.png',
    imageHeaderSize: 'w-[125px]',
    content: '/assets/images/news/exame_image.png',
    contentType: 'image',
    contentClassName: 'w-full',
    className: 'w-full mx-auto',
  },
  {
    newsURL:
      'https://regiao-sul.pt/sociedade/municipio-de-lagos-apoia-promocao-da-cidadania-ativa-nas-escolas-c-video/657106',
    imageHeader: '/assets/images/customers/logo_lagos.png',
    imageHeaderSize: 'w-[125px]',
    className: 'w-full mx-auto',
  },
  {
    newsURL: 'https://escolas.aglousa.com/2023/05/31/assembleia-my-polis/',
    imageHeader: '/assets/images/news/lousa.png',
    imageHeaderSize: 'w-[105px]',
    contentClassName: 'w-full',
    className: 'w-full mx-auto',
  },
];

export const PressList = () => {
  const {t} = useTranslation()

  return (
    <div className="flex flex-col mt-24 mb-12 lg:mt-24 z-10 md:-rotate-[10deg]">
      <div className="flex mb-12 w-full -rotate-[10deg] md:mb-16 md:rotate-0">
        <div className="relative mx-auto">
          <img
            src="/assets/images/other/megaphone.png"
            alt="Megaphone"
            className="absolute -top-10 -left-32 max-w-[125px] rotate-2 md:-top-14 md:-left-56 md:max-w-[170px]"
          />

          <span className="flex text-center text-2xl sm:text-2.5xl font-semibold font-title text-secondary-500">
            {t("history.press")}
          </span>
          <img
            src="/assets/images/other/bum.png"
            alt="Curve"
            className="absolute -top-4 -right-20 max-w-[65px] rotate-[5deg] md:-top-8 md:-right-38 md:max-w-[90px]"
          />
        </div>
      </div>

      <div className="relative z-10 flex-row justify-around hidden mx-auto gap-x-1 mt-36 md:flex">
        {newsListDesktop.map((chunk, j) => (
          <div key={j} className={`flex flex-col gap-y-1`} style={{ marginTop: -4 * j + 'rem' }}>
            {chunk.map((el, i) => {
              return (
                <div key={`${j}${i}`} className={`w-fit ${el.className}`}>
                  <NewsCard
                    newsURL={el.newsURL}
                    imageHeader={el.imageHeader}
                    imageHeaderSize={el.imageHeaderSize}
                    title={el.title}
                    titleClassName={el.titleClassName}
                    content={el.content}
                    contentType={el.contentType}
                    poster={el.poster}
                    contentClassName={el.contentClassName}
                  />
                </div>
              );
            })}
          </div>
        ))}

        <img
          src="/assets/images/other/purple_baloon.png"
          alt="Baloon"
          className="absolute -top-20 left-20 max-w-[70px] -scale-x-100 rotate-[45deg]"
        />

        <img
          src="/assets/images/other/bum.png"
          alt="Bum"
          className="absolute top-10 -right-16 max-w-[70px] -rotate-[15deg]"
        />

        <img
          src="/assets/images/lines/curve.png"
          alt="Curve"
          className="absolute bottom-72 -right-8 max-w-[50px] rotate-[180deg]"
        />

        <img
          src="/assets/images/other/red_baloon_news.png"
          alt="Baloon"
          className="absolute bottom-72 -left-24 max-w-[80px]"
        />

        <img
          src="/assets/images/lines/curve.png"
          alt="Curve"
          className="absolute bottom-48 left-8 max-w-[50px] -rotate-[90deg]"
        />

        <img
          src="/assets/images/other/spring.png"
          alt="Spring"
          className="absolute bottom-32 right-12 max-w-[80px] rotate-[65deg]"
        />

        <img
          src="/assets/images/lines/spiral_down.png"
          alt="Spring"
          className="absolute bottom-0 right-44 max-w-[80px] rotate-[125deg]"
        />

        <img
          src="/assets/images/other/rocketship_news.png"
          alt="Rocketship"
          className="absolute -bottom-32 -left-12 max-w-[275px] rotate-[0deg]"
        />
      </div>

      <div className="relative z-10 flex flex-col justify-around mx-auto mt-12 gap-y-2 md:hidden">
        {newsListMobile.map((el, i) => {
          return (
            <div
              key={`${i}`}
              className={`w-fit ${el.className} -rotate-[10deg] z-20`}
              style={{ marginLeft: 5 * Math.random() + 'rem', marginRight: 2.5 * Math.random() + 'rem' }}
            >
              <NewsCard
                newsURL={el.newsURL}
                imageHeader={el.imageHeader}
                imageHeaderSize={el.imageHeaderSize}
                title={el.title}
                titleClassName={el.titleClassName}
                content={el.content}
                contentType={el.contentType}
                poster={el.poster}
                contentClassName={el.contentClassName}
              />
            </div>
          );
        })}

        <img
          src="/assets/images/other/purple_baloon.png"
          alt="Baloon"
          className="absolute -top-24 right-20 max-w-[70px] -scale-x-100 rotate-[45deg]"
        />

        <img
          src="/assets/images/other/bum.png"
          alt="Bum"
          className="absolute top-72 left-0 max-w-[70px] -rotate-[15deg]"
        />

        <img
          src="/assets/images/other/spring.png"
          alt="Spring"
          className="absolute top-96 right-0 max-w-[80px] rotate-90"
        />

        <img
          src="/assets/images/other/purple_baloon.png"
          alt="Baloon"
          className="absolute top-[45rem] left-0 max-w-[70px]"
        />

        <img
          src="/assets/images/other/red_baloon_news.png"
          alt="Baloon"
          className="absolute top-[60rem] right-0 max-w-[80px] -rotate-45"
        />

        <img
          src="/assets/images/other/red_baloon_news.png"
          alt="Baloon"
          className="absolute top-[80rem] left-0 max-w-[80px] -rotate-45"
        />

        <img
          src="/assets/images/other/spring.png"
          alt="Spring"
          className="absolute top-[100rem] right-0 max-w-[80px] rotate-90"
        />

        <img
          src="/assets/images/other/red_baloon_news.png"
          alt="Baloon"
          className="absolute bottom-96 left-0 max-w-[80px]"
        />

        <img
          src="/assets/images/other/rocketship_news.png"
          alt="Rocketship"
          className="absolute -bottom-12 -right-24 max-w-[275px] -rotate-[30deg]"
        />
      </div>
    </div>
  );
};
