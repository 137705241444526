import { HistoryList } from '../../../organisms/history-list/history-list';
import { ContactUs } from '../../../organisms/contact-us/contact-us';
import { Prizes } from '../../../organisms/prizes/prizes';
import { PressList } from '../../../organisms/press-list/press-list';

export const History = () => {
  return (
    <div id="page" className="history">
      <HistoryList />
      <br />
      <PressList />
      <br />
      <Prizes />
      <br />
      <ContactUs />
    </div>
  );
};

export default History;
