import { RouteObject, redirect } from 'react-router-dom';
import { Layout } from '../templates/layout';
import aboutUsPageRoutes from './about-us';
import errorPageRoutes from './errors';
import grantsRoutes from './grants';
import landingPageRoutes from './home';
import participatePageRoutes from './how-to-participate';
import municipalitiesPageRoutes from './municipalities';
import schoolsPageRoutes from './schools';
import cppRoutes from './child-protection-policy';

const routes: (lang?: string) => RouteObject[] = (lang = 'pt') => [
  {
    path: '/:langId',
    loader: async ({ params, request }) => {
      const url = new URL(request.url);
      if (params.langId === lang) return {};
      let path = url.pathname.split('/');
      path = path.slice(2, path.length);
      return redirect(`/${lang}/${path.join('/')}`);
    },
    element: <Layout />,
    children: [
      ...errorPageRoutes,
      ...landingPageRoutes,
      ...municipalitiesPageRoutes,
      ...schoolsPageRoutes,
      ...aboutUsPageRoutes,
      ...participatePageRoutes,
      ...grantsRoutes,
      ...cppRoutes,
    ],
  },
  {
    path: '*',
    loader: async () => redirect(`/${lang}`),
  },
];

export default routes;
