import { PropsWithChildren } from "react";
import { NavLink } from "react-router-dom";
import "./navbar-link.scss";

type NavbarLinkProps = {
  to?: string;
  containerClasses?: string;
  linkClasses?: string;
  disabled?: boolean;
  modalOpen?: boolean | undefined;
  target?: string;
};

export const NavbarLink = ({
  to = "#",
  containerClasses = "",
  linkClasses = "",
  children = "",
  disabled = false,
  modalOpen,
  target = "",
}: PropsWithChildren<NavbarLinkProps>) => {
  return (
    <NavLink
      id={`nav-link-${to.replace("/", "")}`}
      className={`h-[60px] block hover:underline hover:bg-gray-100 ${linkClasses}`}
      to={to}
      target={target}
    >
      <li
        className={`flex items-center bg-transparent justify-center font-medium px-6 h-[60px] w-auto lg:font-normal  ${containerClasses}`}
      >
        {children}
      </li>
    </NavLink>
  );
};
