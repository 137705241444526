import { Outlet } from 'react-router-dom';
import { Navbar, Footer } from '../organisms';
import { ScrollToTop } from '../../utilities/scroll-to-top';
import { isSafari } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Button } from 'flowbite-react';
import { PopupButton } from 'react-calendly';
import { LanguagePicker } from '../molecules/language-picker/language-picker';

export const Layout = () => {
  const { t } = useTranslation();
  return (
    <>
      <ScrollToTop />
      <Navbar />
      <div className={`page-wrapper relative ${isSafari ? 'pt-0' : 'pt-[60px]'}`}>
        <Outlet />
        <div
          id="floating-buttons-container"
          className="schedule-meeting-container fixed h-screen w-12 top-0 left-0 pointer-events-none z-50"
        >
          <div className="flex items-end relative h-full">
            <div className="flex flex-col absolute left-3 bottom-3 w-full gap-y-4 lg:left-0">
              <div className="relative min-h-12 w-full">
                <LanguagePicker floating />
              </div>
              <div className="relative h-12 w-full">
                <Button
                  className="absolute text-xl rounded-full size-13 shadow-button pointer-events-auto lg:hidden"
                  color="secondary"
                  as={PopupButton}
                  text="🗓️"
                  rootElement={document.getElementById('root') as HTMLElement}
                  url="https://calendly.com/mypoliseu/reuniao-mypolis"
                />
                <Button
                  className={`hidden absolute right-0 transition-all hover:translate-x-[80%] hover:right-[20%] text-lg rounded-r-full shadow-button w-fit min-w-max pr-4 pl-7 md:hidden h-12 pointer-events-auto lg:block`}
                  color="secondary"
                  as={PopupButton}
                  text={`${t('common.scheduleMeeting')}   🗓️`}
                  rootElement={document.getElementById('root') as HTMLElement}
                  url="https://calendly.com/mypoliseu/reuniao-mypolis"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
