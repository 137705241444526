import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import "./language-picker.scss";
import { LanguageButtonFloating } from "../../atoms/language-button-floating/language-button-floating";
import { LanguageButtonFooter } from "../../atoms/language-button-footer/language-button-footer";

type LanguagePickerProps = {
    floating?: boolean
}
export const LanguagePicker = ({ floating = false }: LanguagePickerProps) => {
    const { i18n } = useTranslation(undefined, { useSuspense: false })
    const lang = i18n.language;
    const location = useLocation();
    const navigate = useNavigate();
    const [openDropdown, setOpenDropdown] = useState(false);

    const selectLanguageHandler = async (event: React.MouseEvent<HTMLElement>, langId: string) => {
        await i18n.changeLanguage(langId)
        const [, ...path] = location.pathname.slice(1).split("/");
        setOpenDropdown(false)
        navigate(
            {
                ...location,
                pathname: `/${[langId, ...path].join("/")}`
            },
            { replace: true }
        );

    }

    return floating ? <LanguageButtonFloating lang={lang} openDropdown={openDropdown} selectLanguageHandler={selectLanguageHandler} setOpenDropdown={setOpenDropdown} /> : <LanguageButtonFooter lang={lang} openDropdown={openDropdown} selectLanguageHandler={selectLanguageHandler} setOpenDropdown={setOpenDropdown} />
}