import { Button } from "flowbite-react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

export const TeamForm = () => {
    const { t } = useTranslation(["translation", "routes"])
    return <div className='flex-col items-center hidden w-full -mb-12 '>
        <div className="flex justify-center w-full -mt-10">
            <img
                src="/assets/images/other/dialog_baloons.png"
                alt="Baloons"
                className="max-w-[1050px] mx-auto hidden lg:block"
            />

            <img
                src="/assets/images/other/baloons_spread_mobile.png"
                alt="Baloons"
                className="max-w-[550px] ml-28 block lg:hidden"
            />
        </div>

        <div className="flex flex-col w-full">
            <span className="px-12 my-8 text-2xl font-semibold text-center font-title lg:px-0">
                {t("forms.team.partOfTheTeam")}
            </span>

            <Button pill as={Link} to={t("routes.jobs", { ns: "routes" })} className="w-4/5 mx-auto bg-white text-secondary-500 lg:w-2/5" color="white">
                <span className="font-normal font-title">{t("forms.team.availablePositions")}</span>
            </Button>
        </div>
    </div>
}