import { Link } from "react-router-dom";
import "./partner-list.scss";
import { useTranslation } from 'react-i18next';

export const PartnerList = () => {

  const {t} = useTranslation();

  return (
    <div
      id="partner-list"
      className="flex flex-col mt-12 -mb-6 z-0 py-12 relative"
    >
      <img
        src="/assets/images/arrows/arrow_down_twirl.png"
        alt="Arrow down"
        className="absolute w-30 -top-16 left-0 rotate-180 hidden md:block"
      />

      <div className="flex mb-8 w-full lg:mb-8 z-10">
        <span
          className="mx-auto text-2.5xl font-semibold font-title text-secondary-500
                    text-center px-20 lg:px-0"
        >
          {t("landing.partners.title")}
        </span>
      </div>

      <div
        className="flex flex-col sm:flex-row flex-wrap items-center justify-around
                mx-12 z-10 gap-x-12 gap-y-12 relative"
      >
        <Link to="https://www.50anos25abril.pt/" target="_blank">
          <img
            src="/assets/images/partners/50anos25abril.png"
            alt="Comissão 50 anos 25 de abril"
            className="max-h-[140px]"
          />
        </Link>

        <Link to="https://www.portugal.gov.pt/" target="_blank">
          <img
            src="/assets/images/partners/rep_portuguesa.png"
            alt="República Portuguesa"
            className="max-h-[30px]"
          />
        </Link>
        <Link to="https://inovacaosocial.portugal2020.pt/" target="_blank">
          <img
            src="/assets/images/partners/pis.png"
            alt="Parcerias para o Impacto Social"
            className="max-h-[95px]"
          />
        </Link>

        <Link to="https://casadoimpacto.scml.pt/" target="_blank">
          <img
            src="/assets/images/partners/ci.png"
            alt="Casa do Impacto"
            className="max-h-[55px]"
          />
        </Link>

        <Link to="https://maze-impact.com/" target="_blank">
          <img
            src="/assets/images/partners/maze.png"
            alt="MAZE"
            className="max-h-[35px]"
          />
        </Link>
      </div>
    </div>
  );
};
