import { LanguagesCurrentlyAvailable as Languages } from '../../../i18n';

type LanguageButtonFloatingProps = {
  lang: string;
  openDropdown: boolean;
  setOpenDropdown: Function;
  selectLanguageHandler: Function;
};
export const LanguageButtonFloating = ({
  lang,
  openDropdown,
  setOpenDropdown,
  selectLanguageHandler,
}: LanguageButtonFloatingProps) => {
  return (
    <>
      <div
        className="hidden absolute w-[210px] right-0 transition-all hover:translate-x-[80%] hover:right-[20%] focus-within:translate-x-[80%] focus-within:right-[20%] flex-col justify-end pointer-events-auto min-h-12 lg:flex"
        onMouseLeave={() => setOpenDropdown(false)}
      >
        {lang && (
          <ul
            id="lang-selector"
            className={`flex flex-col items-center justify-end absolute bg-white shadow-button transition-all duration-500 overflow-hidden rounded-r-card ${
              openDropdown && 'open'
            }`}
            style={{
              maxHeight: openDropdown ? `${Languages.length * 48 + 8}px` : '48px',
            }}
          >
            {Languages.reverse().map(
              (l) =>
                lang &&
                l.id !== lang && (
                  <li
                    key={l.id}
                    color="white"
                    className={` flex items-end justify-end gap-x-4 text-xl font-body hover:bg-gray-200 font-normal rounded-none last:rounded-t-card text-secondary-500 w-full px-2 py-2 relative cursor-pointer`}
                    onClick={(e) => selectLanguageHandler(e, l.id)}
                  >
                    {l.label}
                    <img src={`/assets/images/lang/${l.id}.png`} alt="" className="rounded-full size-8 border-1" />
                  </li>
                )
            )}
            <li
              color="white"
              className={`flex items-center justify-between text-xl font-body font-normal shadow-button mt-2 hover:bg-gray-200 rounded-r-full text-secondary-500 w-[210px] h-14 pl-6 px-2 py-2 relative cursor-pointer`}
              onClick={() => setOpenDropdown(!openDropdown)}
            >
              <svg
                id="chevron"
                className="rotate-180"
                width="18"
                height="10"
                viewBox="0 0 18 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9 8.66406L17.0623 0.601805" stroke="black" strokeLinecap="round" />
                <path d="M9.06226 8.66406L0.999998 0.601805" stroke="black" strokeLinecap="round" />
              </svg>
              {Languages.find((l) => l.id === lang)?.label}
              <img src={`/assets/images/lang/${lang}.png`} alt="" className="rounded-full size-8 border-1" />
            </li>
          </ul>
        )}
      </div>
      {/* <div className="absolute flex flex-col justify-end transition-all pointer-events-auto w-13 min-h-13 lg:hidden" onMouseLeave={() => setOpenDropdown(false)}>

            {lang && <ul id="lang-selector" className={`flex flex-col items-center justify-end absolute bg-white shadow-button transition-all duration-500 overflow-hidden rounded-full ${openDropdown && "open"}`} style={{
                maxHeight: openDropdown ? `${(Object.keys(Languages).length * 52) + 4}px` : "52px"
            }}>
                {Object.keys(Languages).reverse().map((langId) =>
                    lang && langId !== lang && <li key={langId} color="white" className={` flex items-center justify-center gap-x-4 text-xl font-body hover:bg-gray-200 font-normal rounded-none pt-2 last:rounded-t-card text-secondary-500 w-full relative cursor-pointer`} onClick={(e) => selectLanguageHandler(e, langId)}>
                        <img src={`/assets/images/lang/${langId}.png`} alt="" className="rounded-full size-10" />
                    </li >
                )}
                <li color="white" className={`flex items-center justify-center text-xl font-body font-normal shadow-button mt-2 hover:bg-gray-200 rounded-full text-secondary-500 w-13 h-13 py-2 relative cursor-pointer`} onClick={() => setOpenDropdown(!openDropdown)}>
                    <img src={`/assets/images/lang/${lang}.png`} alt="" className="rounded-full size-10" />
                </li>
            </ul >}
        </div> */}
    </>
  );
};
