import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { Button } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import toast, { toastConfig } from 'react-simple-toasts';
import { Category } from '../../../utilities/enums';
import { FormHiddenInput } from '../../atoms/form-fields/form-hidden-input';
import { FormTextInput } from '../../atoms/form-fields/form-text-input';
import { FormTextArea } from '../../atoms/form-fields/form-textarea';
import { SuccessToast } from '../../atoms/form-toasts/sucess-toast';
import { HomeFormInputs, homeFormSchema } from './schemas';

toastConfig({ duration: 3000, position: 'bottom-center', clickClosable: true });
export const HomeForm = () => {
  const [selectedCategory, setSelectedCategory] = useState<Category>(Category.POLITICIAN);
  const [hasErrors, setHasErrors] = useState(false);
  const methods = useForm<HomeFormInputs>({
    mode: 'all',
    resolver: yupResolver(homeFormSchema),
    defaultValues: {
      email: '',
      fullName: '',
      message: '',
      phone: '',
      messageType: selectedCategory,
    },
  });

  const { t } = useTranslation();

  useEffect(() => {
    methods.setValue('messageType', selectedCategory);
    methods.clearErrors();
    setHasErrors(false);
  }, [selectedCategory, methods, setHasErrors]);

  const { isSubmitting } = useFormState<HomeFormInputs>({ control: methods.control });

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const onError: SubmitErrorHandler<HomeFormInputs> = (errors, e) => {
    setHasErrors(true);
  };

  const onSubmit: SubmitHandler<HomeFormInputs> = async (data) => {
    try {
      console.log(process.env.REACT_APP_EMAIL_LAMBDA_URL);
      return await recaptchaRef.current
        ?.executeAsync()
        .then(async (token) => {
          data['g-recaptcha-response'] = token ?? undefined;

          return await axios
            .post(process.env.REACT_APP_EMAIL_LAMBDA_URL as string, data)
            .then((result) => {
              toast(<SuccessToast />);
              return;
            })
            .catch((error) => {
              // toast(<ErrorToast />);
              console.error(error);
            });
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.error(error);
    } finally {
      recaptchaRef.current?.reset();
    }
  };

  return (
    <div className={'flex flex-col items-center w-full'}>
      <div className="flex justify-center w-full -mt-10">
        <img
          src="/assets/images/other/dialog_baloons.png"
          alt="Baloons"
          className="max-w-[1050px] mx-auto hidden lg:block"
        />

        <img
          src="/assets/images/other/baloons_spread_mobile.png"
          alt="Baloons"
          className="max-w-[550px] ml-28 block lg:hidden"
        />
      </div>

      <span className="text-[1.75rem] font-semibold font-title text-secondary-500 mb-7 text-center">
        {t('forms.home.title')}
      </span>
      <div className="relative flex flex-col items-start justify-center w-full mb-8 lg:flex-row gap-x-10 forms">
        <div className="relative z-10 flex flex-col items-center justify-center w-full lg:w-2/5 gap-y-4">
          <span>{t('forms.home.selectOptions')}</span>
          <div className="flex flex-row flex-wrap items-center justify-center gap-x-4 gap-y-4">
            <Button
              pill
              color={selectedCategory === 'politician' ? 'primary' : 'white'}
              className="w-64 h-10 font-normal font-title"
              onClick={() => setSelectedCategory(Category.POLITICIAN)}
            >
              {t('forms.home.governmentOfficials')}
            </Button>
            <Button
              pill
              color={selectedCategory === 'teacher' ? 'primary' : 'white'}
              className="font-normal w-44 font-title"
              onClick={() => setSelectedCategory(Category.TEACHER)}
            >
              {t('forms.home.teachers')}
            </Button>
            <Button
              pill
              color={selectedCategory === 'youth' ? 'primary' : 'white'}
              className="font-normal w-44 font-title"
              onClick={() => setSelectedCategory(Category.YOUTH)}
            >
              {t('forms.home.youngPeople')}
            </Button>
            <Button
              pill
              color={selectedCategory === 'other' ? 'primary' : 'white'}
              className="font-normal w-44 font-title"
              onClick={() => setSelectedCategory(Category.OTHER)}
            >
              {t('forms.home.other')}
            </Button>
          </div>
        </div>
        <div className="flex flex-col items-end w-full px-6 mt-4 lg:w-1/2 lg:mt-0 lg:px-0">
          <div className="relative flex flex-col justify-end w-full gap-y-4">
            <FormProvider {...methods}>
              <form
                id="contact-form"
                onSubmit={methods.handleSubmit(onSubmit, onError)}
                className={`flex flex-col z-10 ${selectedCategory} ${hasErrors ? 'gap-y-2' : 'gap-y-4'}`}
              >
                <FormHiddenInput name="messageType" id="message-type" />
                <div className="w-full">
                  <FormTextInput name="fullName" id="full-name" placeholder={t('forms.fields.name')} />
                </div>
                <div className="w-full politician">
                  <FormTextInput name="politicianRole" id="politician-role" placeholder={t('forms.fields.function')} />
                </div>
                <div className="w-full teacher">
                  <FormTextInput
                    name="schoolOrGrouping"
                    id="school-or-grouping"
                    placeholder={t('forms.fields.school')}
                  />
                </div>
                <div className="w-full politician">
                  <FormTextInput name="cityCounsel" id="city-counsel" placeholder={t('forms.fields.municipality')} />
                </div>
                <div className="w-full">
                  <FormTextInput name="email" id="email" placeholder={t('forms.fields.email')} />
                </div>
                <div className="w-full">
                  <FormTextInput name="phone" id="phone" placeholder={t('forms.fields.phone')} />
                </div>
                <div className="w-full" id="textarea">
                  <FormTextArea name="message" id="message" placeholder={t('forms.fields.message')} rows={10} />
                </div>
                {createPortal(
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY as string}
                    size="invisible"
                    ref={recaptchaRef}
                  />,
                  document.body
                )}
                <Button pill color="primary" type="submit" className="self-end w-32" isProcessing={isSubmitting}>
                  {t('forms.sendButton')}
                </Button>
              </form>
            </FormProvider>
          </div>
        </div>

        <img
          src="/assets/images/other/spring.png"
          alt=""
          className="absolute left-0 bottom-1 lg:bottom-auto lg:top-[300px] max-w-[143px] z-0"
        />

        <img
          src="/assets/images/other/white_star.svg"
          alt=""
          className="absolute left-[350px] -bottom-16 lg:bottom-auto lg:top-[350px] max-w-[38px] z-0"
        />

        <img
          src="/assets/images/other/rocketship.png"
          alt=""
          className="absolute -rotate-[180deg] -scale-y-100 left-[200px] top-16 lg:top-[400px]
            max-w-[320px] z-0"
        />
      </div>
    </div>
  );
};
