// import { PartnerCarousel } from "../../molecules/partner-carousel";

import { GrantsList } from '../../organisms/grants-list/grants-list';

export const Grants = () => {
  return (
    <div id="page" className="flex flex-col tools">
      <GrantsList />
    </div>
  );
};

export default Grants;
