import { Button, Modal } from 'flowbite-react';
import { ImplementedProposal } from '../../../api';
import React from 'react';
import { sanitize } from 'dompurify';
import { useTranslation } from 'react-i18next';

type ProposalModalProps = {
  proposal: Required<ImplementedProposal>;
  openModal: boolean;
  modalCloseHandler: React.Dispatch<any>;
};

export const ProposalModal = ({ proposal, openModal, modalCloseHandler }: ProposalModalProps) => {
  const { t } = useTranslation();

  const innerImages = ([] as string[])
    .concat([proposal?.banner] ?? [])
    .concat(proposal?.images ?? [])
    .filter((el) => el?.trim() !== '');

  return (
    <Modal size="5xl" dismissible show={openModal} onClose={() => modalCloseHandler(false)}>
      <Modal.Body className="relative h-full overflow-y-auto scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-transparent scrollbar-thin">
        <div className="absolute top-0 w-12 h-12 right-6">
          <Button
            pill
            id="flip"
            color="white"
            className="fixed flex flex-col justify-center w-12 h-12 rotate-45 bg-white border-solid rounded-full cursor-pointer shadow-button border-primary-200"
            onClick={() => modalCloseHandler(false)}
          >
            <svg
              className="w-8 h-8 m-auto text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 18 18"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 1v16M1 9h16"
              />
            </svg>
          </Button>
        </div>
        <div className="flex flex-col w-full h-full py-2">
          <span className="px-8 mb-8 text-2xl font-title">{proposal?.title ?? 'No title provided'}</span>
          <div>
            <div className={`flex flex-row px-4 pb-4 overflow-x-auto gap-x-8 ${innerImages.length === 0 && 'hidden'}`}>
              {innerImages.map((img, i) => {
                if (img?.includes('.mp4')) {
                  return (
                    <video
                      key={`video-${i}`}
                      className="max-h-[350px] max-w-none mx-auto rounded-card shadow-card"
                      src={img}
                      controls
                      muted
                    ></video>
                  );
                } else {
                  return (
                    <img
                      key={`img-${i}`}
                      className="max-h-[350px] max-w-none mx-auto rounded-card shadow-card"
                      src={img}
                      alt={`Proposal figure number ${i + 1}`}
                    />
                  );
                }
              })}
            </div>
          </div>
          <div className="flex flex-col w-full lg:w-2/3">
            <span className="p-8 text-lg" dangerouslySetInnerHTML={{ __html: sanitize(proposal?.body || '') }}></span>
            <div className="w-1/3 mx-8 mb-8 border-0 border-b-2 border-b-secondary-500"></div>
            <span className="px-8 mb-4 text-xl font-title">{t('proposalModal.technicalSheet.title')}:</span>
            {proposal?.technicalSheet?.proposalCreators && proposal?.technicalSheet?.proposalCreators.length && (
              <>
                <span className="px-8 text-base font-title">{t('proposalModal.technicalSheet.createdBy')}:</span>
                <span className="px-8 mb-4 text-base">
                  {proposal?.technicalSheet?.proposalCreators.map((el, i) => {
                    if (proposal?.technicalSheet?.proposalCreators) {
                      if (i === proposal?.technicalSheet?.proposalCreators.length - 1) {
                        return el;
                      } else {
                        return el + ', ';
                      }
                    }

                    return el;
                  })}
                  .
                </span>
              </>
            )}

            {proposal?.technicalSheet?.proposalImplementers &&
              proposal?.technicalSheet?.proposalImplementers.length && (
                <>
                  <span className="px-8 text-base font-title">{t('proposalModal.technicalSheet.implementedBy')}:</span>
                  <span className="px-8 mb-4 text-base">
                    {proposal?.technicalSheet?.proposalImplementers.map((el, i) => {
                      if (proposal?.technicalSheet?.proposalImplementers) {
                        if (i === proposal?.technicalSheet?.proposalImplementers.length - 1) {
                          return el;
                        } else {
                          return el + '; ';
                        }
                      }
                      return el;
                    })}
                    .
                  </span>
                </>
              )}

            {proposal?.technicalSheet?.school && proposal?.technicalSheet?.school.length && (
              <>
                <span className="px-8 text-base font-title">{t('proposalModal.technicalSheet.school')}:</span>
                <span className="px-8 mb-4 text-base">{proposal?.technicalSheet?.school}.</span>
              </>
            )}

            {proposal?.technicalSheet?.class && proposal?.technicalSheet?.class.length && (
              <>
                <span className="px-8 text-base font-title">{t('proposalModal.technicalSheet.class')}:</span>
                <span className="px-8 mb-4 text-base">{proposal?.technicalSheet?.class}.</span>
              </>
            )}

            {proposal?.technicalSheet?.accountableTeacher && proposal?.technicalSheet?.accountableTeacher.length && (
              <>
                <span className="px-8 text-base font-title">
                  {t('proposalModal.technicalSheet.accountableTeacher')}:
                </span>
                <span className="px-8 text-base">{proposal?.technicalSheet?.accountableTeacher}.</span>
              </>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
