import { sanitize } from 'dompurify';
import { StepFigure } from '../../atoms/step-figure/step-figure';
import './step-text-figure.scss';

export const StepTextFigure = ({
  title,
  text,
  maxLength,
  srcImage,
  description = '',
  reverse = false,
  index,
}: {
  title: string;
  text: string;
  maxLength: string;
  srcImage: string;
  description?: string;
  reverse?: boolean;
  index: number;
}) => {
  return (
    <div className={`flex gap-x-14 pb-0 flex-wrap  lg:flex-nowrap ${reverse && 'lg:flex-row-reverse'}`}>
      <div
        className={`flex items-end justify-between w-full px-9 ${!reverse && 'flex-row-reverse'} lg:justify-start lg:w-1/2`}
      >
        <StepFigure className="mb-6 drop-shadow" srcImage={srcImage} description={description} />
        <div
          className="flex items-center justify-center w-20 h-20 text-4xl font-semibold step-number font-title lg:hidden"
          style={{ background: 'url(/assets/images/other/yellow_round_star.png)' }}
        >
          {index}
        </div>
      </div>

      <div className="flex flex-col px-8 mb-4 lg:px-0 lg:mb-0 lg:w-1/2">
        <div
          className={`step-number justify-center items-center w-20 h-20 font-title text-4xl font-semibold hidden lg:flex`}
          style={{ background: 'url(/assets/images/other/yellow_round_star.png)' }}
        >
          {index}
        </div>
        <span className={'text-2xl font-title font-medium ' + maxLength}>{title}</span>
        <span
          className={'text-base ' + (title.length > 0 ? 'pt-4' : '')}
          dangerouslySetInnerHTML={{ __html: sanitize(text) }}
        ></span>
      </div>
    </div>
  );
};
