export const NotFound = () => {
  return (
    <div className="flex flex-col font-title items-center justify-center w-screen mt-15 h-full text-secondary-500">
      <span className="text-8xl font-bold w-fit text-center">404</span>
      <span className="text-xl font-normal mt-4">Ups! Não é por aqui!</span>
      <span className="text-base font-light mt-3">
        A página que procuras não existe
      </span>
      <div className="mt-6 -mb-[55vw] w-2full lg:w-[88vw] lg:-mb-[26vw]">
        <img
          className="mx-auto"
          src="/assets/images/errors/crashed-rocket.png"
          alt="Crashed rocket"
        />
      </div>
    </div>
  );
};
