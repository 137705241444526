import { Button } from 'flowbite-react';
import { StepTextFigure } from '../../molecules/step-text-figure/step-text-figure';
import { useTranslation } from 'react-i18next';

type StepListType = {
  order: number;
  title: string;
  text: string;
  image: string;
};

export const StepsToParticipate = () => {
  const { t } = useTranslation()
  const steps: StepListType[] = t("htp.stepList.steps", { returnObjects: true })

  return (
    <div className="steps-to-participate flex flex-col items-center mt-20 text-secondary-500 mb-38 lg:px-[90px]">
      <div className="relative flex mb-8 rotate-5">
        <img
          src="/assets/images/arrows/arrow_down_twirl.png"
          alt="spiral"
          className="absolute max-w-[98px] -right-6 -top-18 -rotate-5"
        />
        <span className="px-5 py-2 my-auto text-2xl font-semibold text-white rounded-full font-title bg-danger-500">
          {t("htp.stepList.title")}
        </span>
      </div>

      {steps && typeof steps === "object" && steps
        .sort((a, b) => (a.order < b.order ? -1 : a.order > b.order ? 1 : 0))
        .map((el, i) => {
          return (
            <StepTextFigure
              title={el.title}
              text={el.text}
              maxLength="max-w-[85%]"
              srcImage={el.image}
              reverse={i % 2 === 0}
              key={i}
              index={i + 1}
            />
          );
        })}

      <div className="flex flex-col items-center w-full pt-11">
        <div className="lg:w-full">
          <div className="flex justify-start lg:w-full">
            <div className="flex flex-col items-center justify-center font-semibold font-title text-2xl relative -rotate-5 lg:rotate-5 lg:w-fit lg:mr-auto lg:text-[28px]">
              <img
                className="absolute w-38 -z-10 -left-7 -top-15 lg:-left-[50px] lg:w-52 lg:-top-24"
                src="/assets/images/other/yellow_bean.png"
                alt="Forma amarela"
              />
              <span>
                {t("htp.stepList.cta.text1")} <span className="px-1 text-white bg-primary-500 w-fit">{t("htp.stepList.cta.text2")}</span>&nbsp;
                <span className="hidden lg:inline-block">{t("htp.stepList.cta.text3")}</span>
              </span>
              <span>
                <span className="lg:hidden">{t("htp.stepList.cta.text3")}</span> {t("htp.stepList.cta.text4")}
              </span>
              <img
                className="absolute hidden w-30 -scale-x-100 -right-32 lg:block"
                src="/assets/images/arrows/arrow_down_twirl.png"
                alt=""
              />
            </div>
          </div>
          <div className="w-full">
            <div className="relative flex justify-center w-full lg:w-1/2 lg:ml-auto lg:-rotate-5 lg:pr-5">
              <img
                className="absolute left-0 w-32 top-12 lg:left-6"
                src="/assets/images/other/explosion_yellow_baloon.png"
                alt="Baloons and Star"
              />
              <img
                className="absolute w-32 -right-7 top-6 lg:right-2"
                src="/assets/images/other/purple_baloon_star.png"
                alt="Baloons and Star"
              />
              <Button
                pill
                href="https://community.mypolis.eu/register"
                target="_blank"
                color="primary"
                className="flex mt-20 w-44 h-13 lg:w-54"
              >
                <span className="text-base font-semibold lg:text-xl">{t("common.createAcct")}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
