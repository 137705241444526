// import { PartnerCarousel } from "../../molecules/partner-carousel";

import { ToolsList } from "../../../organisms/tools-list/tools-list";

export const Tools = () => {
  return (
    <div id="page" className="flex flex-col tools">
      <ToolsList />
    </div>
  );
};

export default Tools;
