import {Metrics} from "../../organisms/metrics/metrics";
import {ContactUs} from "../../organisms/contact-us/contact-us";
import {Feedback} from "../../organisms/feedback/feedback";
import {HeroSchools} from "../../organisms/hero-schools/hero-schools";
import {ScheduleMeeting} from "../../molecules/schedule-meeting/schedule-meeting";
import {SchoolsImpact} from "../../organisms/schools-impact/schools-impact";
import {Tools} from "../../organisms/tools/tools";

const Schools = () => {
    return (
        <div
            id="page"
            className="schools"
        >
            <HeroSchools/>

            <Metrics className="schools"/>

            <ScheduleMeeting />

            <SchoolsImpact />
            <br/>
            <Tools schoolsPage={true}/>
            <Feedback schoolsPage={true}/>
            <br/>
            <ContactUs className={"schools"}/>
        </div>
    );
};

export default Schools;
