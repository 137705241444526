type CardBodyOptions = {
  text: string;
  fontFamily?: string;
  fontSize?: string;
};

const CardBody = ({ text, fontFamily, fontSize }: CardBodyOptions) => (
  <p
    style={{
      fontFamily, fontSize
    }}
  >
    {text}
  </p>
);

export default CardBody;
