import { sanitize } from 'dompurify';

export const Figure = ({
  srcImage,
  description,
  maxWidth = 'max-w-[350px]',
  className,
  textWeight = 'italic',
}: {
  srcImage: string;
  description: string;
  maxWidth?: string;
  className?: string;
  textWeight?: string;
}) => {
  return (
    <div className={`flex flex-col w-full lg:w-auto ${className ?? ''}`}>
      <img src={srcImage} alt="Impact" className={'mx-auto rounded-card drop-shadow lg:mx-0 ' + maxWidth} />

      <span
        className={`mt-4 text-xs font-medium text-center ${textWeight}`}
        dangerouslySetInnerHTML={{ __html: sanitize(description) }}
      ></span>
    </div>
  );
};
