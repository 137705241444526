import { History, ImpactPage, Team, Tools } from './sub-pages';

const aboutUsPageRoutes = [
  { path: 'sobre-nos/historia', element: <History /> },
  { path: 'sobre-nos/equipa', element: <Team /> },
  { path: 'sobre-nos/ferramentas', element: <Tools /> },
  { path: 'sobre-nos/impacto', element: <ImpactPage /> },
  { path: 'about-us/history', element: <History /> },
  { path: 'about-us/team', element: <Team /> },
  { path: 'about-us/tools', element: <Tools /> },
  { path: 'about-us/impact', element: <ImpactPage /> },
];

export default aboutUsPageRoutes;
