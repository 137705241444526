import { HeroImpact } from "../../../organisms/hero-impact/hero-impact";
import { ImpactList } from "../../../organisms/impact-list/impact-list";
import { Impact } from "../../../organisms/impact/impact";
import { ImplementedProposals } from "../../../organisms/implemented-proposals/implemented-proposals";
import { Feedback } from "../../../organisms/feedback/feedback";
import { CustomerMap } from "../../../organisms/customer-map/customer-map";
import { useTranslation } from "react-i18next";

export const ImpactPage = () => {
  const {t} = useTranslation()
  return (
    <div id="page" className="impact">
      <HeroImpact />

      <ImpactList />

      <div className="flex w-full mt-10 -mb-24 scale-75 lg:mb-0">
        <img
          src="/assets/images/impact/target.png"
          alt="Target"
          className="max-w-[435px] mx-auto"
        />
      </div>

      <Impact titleVisible={false} />
      <br />
      {t("impact.map.title")}
      <CustomerMap title={t("impact.map.title")} showTitleIcons={false}/>
      <ImplementedProposals />
      <br />
      <Feedback schoolsPage={true} />
    </div>
  );
};

export default Impact;
