import * as yup from 'yup';
import { Category } from '../../../utilities/enums';

export const homeSchema = yup.object({
  fullName: yup.string().required('Este campo não pode estar vazio.'),
  email: yup
    .string()
    .required('Este campo não pode estar vazio.')
    .email('Este campo tem que conter um endereço de email válido.'),
  phone: yup
    .string()
    .required('Este campo não pode estar vazio.')
    .matches(
      /^(9[1,2,3,6]\d{7}$|2[12345678]\d{7}|\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)[ ]{0,1}\d{1,14})$/,
      'Este campo tem que conter um contacto telefónico válido.'
    ),
  message: yup.string().required('Este campo não pode estar vazio.'),
  messageType: yup.mixed<Category>().oneOf(Object.values(Category)).required('Este campo não pode estar vazio.'),
  'g-recaptcha-response': yup.string().optional(),
});

export const politicianSchema = yup.object({
  cityCounsel: yup.string().when('messageType', {
    is: Category.POLITICIAN,
    then: () => yup.string().required('Este campo não pode estar vazio.'),
    otherwise: () => yup.string().optional(),
  }),
  politicianRole: yup.string().when('messageType', {
    is: Category.POLITICIAN,
    then: () => yup.string().required('Este campo não pode estar vazio.'),
    otherwise: () => yup.string().optional(),
  }),
});

export const schoolSchema = yup.object({
  schoolOrGrouping: yup.string().when('messageType', {
    is: Category.TEACHER,
    then: () => yup.string().required('Este campo não pode estar vazio.'),
    otherwise: () => yup.string().optional(),
  }),
});

export const participateFormSchema = yup.object({
  fullName: yup.string().required('Este campo não pode estar vazio.'),
  email: yup
    .string()
    .required('Este campo não pode estar vazio.')
    .email('Este campo tem que conter um endereço de email válido.'),
  territoryOrCommunity: yup.string().required('Este campo não pode estar vazio.'),
  message: yup.string().required('Este campo não pode estar vazio.'),
  messageType: yup.mixed<Category>().oneOf(Object.values(Category)).required('Este campo não pode estar vazio.'),
  'g-recaptcha-response': yup.string().optional(),
});

export const homeFormSchema = homeSchema.concat(politicianSchema).concat(schoolSchema);

export const politicianFormSchema = homeSchema.concat(politicianSchema);
export const schoolFormSchema = homeSchema.concat(schoolSchema);

export type HomeFormInputs = yup.InferType<typeof homeFormSchema>;
export type PoliticianFormInputs = yup.InferType<typeof politicianFormSchema>;
export type SchoolFormInputs = yup.InferType<typeof schoolFormSchema>;
export type ParticipateFormInputs = yup.InferType<typeof participateFormSchema>;
