import { useTranslation } from 'react-i18next';
import { FlippableCard } from '../../molecules/flippable-card/flippable-card';
import { sanitize } from 'dompurify';

export const Metrics = ({ className = '' }: any) => {
  const { t } = useTranslation(["metrics"])

  let cards = undefined;

  if (className === 'schools') {
    cards = (
      <div className="flex flex-row flex-wrap items-center justify-center w-full mx-auto cards perspective gap-[50px]">
        <FlippableCard
          imgSrc="/assets/images/cards/schools_card_1.png"
          cardTitle={t("schools.firstCard.title")}
          buttons={true}
        >
          <div>
            <span className="text-lg lg:text-sm" dangerouslySetInnerHTML={{ __html: sanitize(t("schools.firstCard.content1")) }}></span>
          </div>
          <div>
            <div className='mt-2 mb-1 text-3xl font-medium font-title'>
              {t("schools.firstCard.highlight")}
            </div>
            <span className='text-lg lg:text-sm' dangerouslySetInnerHTML={{ __html: sanitize(t("schools.firstCard.content2")) }}>
            </span>
          </div>
        </FlippableCard>

        <FlippableCard imgSrc="/assets/images/cards/schools_card_2.png" cardTitle={t("schools.secondCard.title")} buttons={true}>
          <div>
            <span className="text-lg lg:text-sm" dangerouslySetInnerHTML={{ __html: sanitize(t("schools.secondCard.content1")) }}></span>
          </div>
          <div>
            <span className="text-lg lg:text-sm" dangerouslySetInnerHTML={{ __html: sanitize(t("schools.secondCard.content2")) }}></span>
          </div>
        </FlippableCard>
        <FlippableCard
          imgSrc="/assets/images/cards/schools_card_3.png"
          cardTitle={t("schools.thirdCard.title")}
          buttons={true}
        >
          <div>
            <span className="text-lg lg:text-sm" dangerouslySetInnerHTML={{ __html: sanitize(t("schools.thirdCard.content1")) }}>
            </span>
          </div>
          <div>
            <div className="mt-2 mb-1 text-3xl font-medium font-title">{t("schools.thirdCard.highlight")}</div>
            <span className="text-lg lg:text-sm" dangerouslySetInnerHTML={{ __html: sanitize(t("schools.thirdCard.content2")) }}>
            </span>
          </div>
        </FlippableCard>
        <FlippableCard imgSrc="/assets/images/cards/schools_card_4.png" cardTitle={t("schools.fourthCard.title")} buttons={true}>
          <div>
            <span className="text-lg lg:text-sm" dangerouslySetInnerHTML={{ __html: sanitize(t("schools.fourthCard.content1")) }}>
            </span>
          </div>
          <div>
            <div className="mb-1 text-3xl font-medium font-title">{t("schools.fourthCard.highlight")}</div>
            <span className="text-lg lg:text-sm" dangerouslySetInnerHTML={{ __html: sanitize(t("schools.fourthCard.content2")) }}>
            </span>
          </div>
        </FlippableCard>
      </div>
    );
  } else if (className === 'municipalities') {
    cards = (
      <div
        className="flex flex-row flex-wrap items-center justify-center w-full mx-auto cards perspective gap-[30px]
                lg:flex-nowrap"
      >
        <FlippableCard
          imgSrc="/assets/images/cards/municipalities_1.png"
          cardTitle={t("localAuthorities.firstCard.title")}
          buttons={true}
        >
          <div>
            <span className="text-lg lg:text-sm" dangerouslySetInnerHTML={{ __html: sanitize(t("localAuthorities.firstCard.content1")) }}>
            </span>
          </div>
          <div>
            <div className="mb-1 text-3xl font-medium font-title">{t("localAuthorities.firstCard.highlight1")}</div>
            <span className="text-lg lg:text-sm" dangerouslySetInnerHTML={{ __html: sanitize(t("localAuthorities.firstCard.content2")) }}>
            </span>
          </div>
        </FlippableCard>
        <FlippableCard
          imgSrc="/assets/images/cards/municipalities_2.png"
          cardTitle={t("localAuthorities.secondCard.title")}
          buttons={true}
        >
          <div>
            <span className="text-lg lg:text-sm" dangerouslySetInnerHTML={{ __html: sanitize(t("localAuthorities.secondCard.content1")) }}>
            </span>
          </div>
          <div>
            <div className="mb-1 text-3xl font-medium font-title">{t("localAuthorities.secondCard.highlight1")}</div>
            <span className="text-lg lg:text-sm" dangerouslySetInnerHTML={{ __html: sanitize(t("localAuthorities.secondCard.content2")) }}>
            </span>
          </div>
        </FlippableCard>
        <FlippableCard
          imgSrc="/assets/images/cards/municipalities_3.png"
          cardTitle={t("localAuthorities.thirdCard.title")}
          buttons={true}
        >
          <div>
            <span className="text-lg lg:text-sm" dangerouslySetInnerHTML={{ __html: sanitize(t("localAuthorities.thirdCard.content1")) }}>
            </span>
          </div>
          <div>
            <div className="mb-1 text-3xl font-medium font-title">{t("localAuthorities.thirdCard.highlight1")}</div>
            <span className="text-lg lg:text-sm" dangerouslySetInnerHTML={{ __html: sanitize(t("localAuthorities.thirdCard.content2")) }}>
            </span>
          </div>
        </FlippableCard>
      </div>
    );
  } else {
    cards = (
      <div
        className="flex flex-row flex-wrap items-center justify-center w-full mx-auto cards perspective gap-[30px]
                lg:flex-nowrap"
      >
        <FlippableCard
          imgSrc="/assets/images/cards/what_we_do_card_1.png"
          cardTitle={t("landing.firstCard.title")}
          buttons={true}
        >
          <div>
            <div className="text-2.5xl font-title font-medium mb-1 lg:mb-3">{t("landing.firstCard.highlight1")}</div>
            <span className="text-lg lg:text-sm" dangerouslySetInnerHTML={{ __html: t("landing.firstCard.content1") }}>

            </span>
          </div>
          <div>
            <div className="text-2.5xl font-title font-medium mt-2 mb-1 lg:mb-3">{t("landing.firstCard.highlight2")}</div>
            <span className="text-lg lg:text-sm" dangerouslySetInnerHTML={{ __html: t("landing.firstCard.content2") }}>
            </span>
          </div>
        </FlippableCard>
        <FlippableCard
          imgSrc="/assets/images/cards/what_we_do_card_2.png"
          cardTitle={t("landing.secondCard.title")}
          buttons={true}
          className="flex flex-col"
        >
          <div>
            <div className="text-2.5xl font-title font-medium mb-1 lg:mb-3">{t("landing.secondCard.highlight1")}</div>
            <span className="text-lg lg:text-sm" dangerouslySetInnerHTML={{ __html: t("landing.secondCard.content1") }}>
            </span>
          </div>
          <div>
            <div className="text-2.5xl font-title font-medium mt-2 mb-1 lg:mb-3">{t("landing.secondCard.highlight2")}</div>
            <span className="text-lg lg:text-sm" dangerouslySetInnerHTML={{ __html: t("landing.secondCard.content2") }}>
            </span>
          </div>
        </FlippableCard>
        <FlippableCard
          imgSrc="/assets/images/cards/what_we_do_card_3.png"
          cardTitle={t("landing.thirdCard.title")}
          buttons={true}
        >
          <div>
            <div className="text-2.5xl font-title font-medium mb-1 lg:mb-3">{t("landing.thirdCard.highlight1")}</div>
            <span className="text-lg lg:text-sm" dangerouslySetInnerHTML={{ __html: t("landing.thirdCard.content1") }}>
            </span>
            <span className="text-sm font-thin leading-6 lg:text-xs lg:leading-4" dangerouslySetInnerHTML={{ __html: t("landing.thirdCard.explainer1") }}>
            </span>
          </div>
          <div>
            <div className="text-2.5xl font-title font-medium mt-2 mb-1 lg:mb-3">{t("landing.thirdCard.highlight2")}</div>
            <span className="text-lg lg:text-sm" dangerouslySetInnerHTML={{ __html: t("landing.thirdCard.content2") }}>
            </span>
            <span className="text-sm font-thin leading-6 lg:text-xs lg:leading-4" dangerouslySetInnerHTML={{ __html: t("landing.thirdCard.explainer2") }}>
            </span>
          </div>
        </FlippableCard>
      </div>
    );
  }

  return (
    <div className={className + ' metrics lg:px-12 flex flex-col items-center justify-center mt-40 gap-y-6'}>
      <div className="relative flex flex-row">
        <span className="m-auto text-2.5xl font-semibold font-title">{t("title")}</span>
        <img src="/assets/images/arrows/arrow-left.svg" alt="" className="absolute -right-32 -top-5" />
      </div>
      <span className="px-8 m-auto text-base text-center font-body lg:px-0">
        {t("subTitle")}
      </span>
      {cards}
    </div>
  );
};
