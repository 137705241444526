import { Textarea } from 'flowbite-react';
import { useFormContext } from 'react-hook-form';

type FormTextareaProps = {
  id: string;
  name: string;
  placeholder: string;
  rows: number;
  className?: string;
};

export const FormTextArea = ({ name, placeholder, id, rows = 10, className = '' }: FormTextareaProps) => {
  const { register, getFieldState, formState } = useFormContext();

  const { isDirty, isTouched, invalid, error } = getFieldState(name, formState);

  return (
    <Textarea
      id={id}
      {...register(name)}
      placeholder={placeholder}
      color={`${(isTouched || isDirty || invalid) && (error ?? false) ? 'failure' : 'input'}`}
      helperText={
        <>
          {((isTouched || isDirty || invalid) && error && <span className="text-xs pl-2 pt-1">{error.message}</span>) ??
            ''}
        </>
      }
      rows={rows}
      className={`resize-none ${className ?? ''}`}
    />
  );
};
