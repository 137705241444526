import { useFormContext } from 'react-hook-form';

type FormTextInputProps = {
  id: string;
  name: string;
};

export const FormHiddenInput = ({ name, id }: FormTextInputProps) => {
  const { register } = useFormContext();

  return <input type="hidden" id={id} {...register(name)} />;
};
