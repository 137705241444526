import { useTranslation } from 'react-i18next';
import { Grant } from '../../../api';
import { chunkify } from '../../../utilities/index';
import { GrantListCard } from '../../molecules/grant-list-card/grant-list-card';

export type Category = { id: string; title: string; order: number };

export type Categories = {
  [key: string]: string;
};

export const GrantsList = () => {
  const { t } = useTranslation(['translation', 'grants']);
  const grants: Grant[] = t('grants', { ns: 'grants', returnObjects: true });

  return (
    <>
      {grants && typeof grants === 'object' && (
        <div className="z-10 flex flex-col items-center w-full mt-24 mb-12 tools-list lg:mt-36">
          <div className="flex flex-col mx-auto mb-24 w-fit lg:mb-28">
            <div className="relative mx-auto text-center bg-white px-18 py-7 lg:px-28 lg:py-12 rounded-card">
              <img
                src="/assets/images/other/tools_baloons_left.png"
                alt="Baloon"
                className="absolute left-4 h-[190px] -top-10 block lg:hidden"
              />
              <span className="text-2xl sm:text-2.5xl font-semibold font-title text-secondary-500">
                {t('grants.title')}&nbsp;
              </span>
              <img
                src="/assets/images/other/tools_baloons_right.png"
                alt="Curve"
                className="absolute h-[200px] right-4 -top-7 block lg:hidden"
              />
              <img
                src="/assets/images/tools/tools-list/title_desktop.png"
                alt="Curve"
                className="absolute max-w-[465px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 hidden lg:block"
              />
            </div>
          </div>
          <div className="relative grid w-full grid-cols-1 gap-y-8 gap-x-8 px-11 lg:grid-flow-row lg:grid-cols-2">
            {chunkify<Grant>(grants, 2, true).map((chunk, i) => (
              <div key={i} className="flex flex-col w-full gap-y-8">
                {chunk.map((grant) => (
                  <GrantListCard key={grant.id} grant={grant} />
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
