import { useLocation } from 'react-router-dom';
import { HistoryForm } from '../../molecules/forms/history-form';
import { HomeForm } from '../../molecules/forms/home-form';
import { ParticipateForm } from '../../molecules/forms/participate-form';
import { PoliticianForm } from '../../molecules/forms/politician-form';
import { SchoolForm } from '../../molecules/forms/school-form';
import { TeamForm } from '../../molecules/forms/team-form';
import './contact-us.scss';

export type RECaptchaField = {
  'g-recaptcha-response'?: string | undefined;
};

export const ContactUs = ({ className = '' }) => {
  const location = useLocation();

  return (
    <div className="relative z-0 flex w-full -mt-10 contact-us lg:mt-0">
      {location.pathname.match(/^(\/\w{2}|\/\w{2}\/)$/) ? (
        <HomeForm />
      ) : location.pathname.match(/\/local-authorities|\/autarquias/) ? (
        <PoliticianForm />
      ) : location.pathname.match(/\/schools|\/escolas/) ? (
        <SchoolForm />
      ) : location.pathname.match(/\/how-to-participate|\/como-participar/) ? (
        <ParticipateForm />
      ) : location.pathname.match(/\/history|\/historia/) ? (
        <HistoryForm />
      ) : location.pathname.match(/\/team|\/equipa/) ? (
        <TeamForm />
      ) : (
        <></>
      )}
    </div>
  );
};
