import { ContactUs } from '../../organisms/contact-us/contact-us';
import { FAQ } from '../../organisms/faq/faq';
import { Feedback } from '../../organisms/feedback/feedback';
import { HeroHowToParticipate } from '../../organisms/hero-how-to-participate/hero-how-to-participate';
import { StepsToParticipate } from '../../organisms/steps-to-participate/steps-to-participate';
import './how-to-participate.scss';

const HowToParticipate = () => {
  return (
    <div id="page" className="how-to-participate">
      <HeroHowToParticipate />
      <StepsToParticipate />
      <Feedback />
      <ContactUs />
      <FAQ />
    </div>
  );
};

export default HowToParticipate;
