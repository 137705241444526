import { useTranslation } from "react-i18next";
import "./hero-impact.scss";

export const HeroImpact = ({ className }: any) => {
  const { t } = useTranslation()
  return (
    <div
      className={
        className +
        " hero-impact gap-x-2 flex flex-col justify-around w-full pt-10 lg:flex-row -mb-10 lg:mb-0"
      }
    >
      <div className="relative flex flex-col items-center justify-around w-full gap-y-10 lg:w-1/2">
        <div
          className="flex flex-col items-start justify-center w-full px-4 text-3xl text-secondary-500 font-title gap-y-2 lg:-rotate-5 lg:text-5xl"
        >
          <div className="flex flex-col mx-auto w-fit">
            <span className="px-4 w-fit lg:mt-8">{t("impact.hero.weAre")}</span>
            <span className="px-4 py-2 w-fit">{t("impact.hero.reinforcing")}</span>
            <span className="px-2 py-2 ml-2 text-white bg-secondary-500 w-fit lg:px-4 lg:ml-0">
              {t("impact.hero.theQuality")}
            </span>
            <span className="px-4 py-2 w-fit">{t("impact.hero.ofOur")}</span>
            <span className="px-4 py-2 text-white rounded-full bg-danger-500 lg:bg-secondary-500 w-fit">
              {t("impact.hero.democracy")}
            </span>

            <div className="px-4 mt-6 text-base text-primary-900/50 lg:mt-2">
              {t("impact.hero.subText")}
            </div>
          </div>
        </div>

        <img
          className="max-w-[50px] absolute top-24 right-0 brightness-0 hidden lg:block"
          src="/assets/images/other/white_star.svg"
          alt="Star"
        />

        <img
          className="max-w-[30px] absolute top-48 -right-5 brightness-0 hidden lg:block"
          src="/assets/images/other/white_star.svg"
          alt="Star"
        />

        <img
          className="max-w-[68px] rotate-[13deg] absolute top-[120px] right-20 lg:hidden"
          src="/assets/images/other/yellow_baloon_straight.png"
          alt="Star"
        />

        <img
          className="max-w-[70px] absolute top-60 right-16 lg:hidden"
          src="/assets/images/other/purple_dialog_baloon.png"
          alt="Star"
        />
      </div>

      <div className="w-full pt-8 lg:w-1/2">
        <img
          className="hidden w-4/5 mx-auto lg:block"
          src="/assets/images/other/growing.png"
          alt="Plant"
        />
        <img
          className="w-4/5 max-w-[315px] mx-auto lg:hidden"
          src="/assets/images/other/growing_mobile.png"
          alt="Plant"
        />
      </div>
    </div>
  );
};
