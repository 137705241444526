import React from "react";

export interface AppState {
    fireAnimations: boolean;
}

export const defaultAppState = {
    fireAnimations: false,
    setFireAnimations: (v: boolean) => {}
}

export const AppContext = React.createContext(defaultAppState) 