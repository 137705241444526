import { Accordion, Button } from 'flowbite-react';
import { simpleSortForOrderedObjects } from '../../../utilities';
import { sanitize } from 'dompurify';
import './faq.scss';
import { useTranslation } from 'react-i18next';

export const FAQ = () => {
  const { t } = useTranslation();

  const faqItems: {
    title: string,
    order: number,
    content: string
  }[] = t("faq.items", { returnObjects: true })

  console.log(faqItems)

  const expand = (e: React.MouseEvent) => {
    console.log(e.currentTarget)
    const title = e.currentTarget.closest('.faq-title') as Element;

    if (!title) {
      console.error('Element not populated on click');
      return;
    }

    for (const item of document.getElementsByClassName('faq-title')) {
      if (item.id !== title.id) {
        item.nextElementSibling?.setAttribute('hidden', "");
        item.classList.remove('active');
      }
    }

    title.nextElementSibling!.toggleAttribute("hidden");
    (title ?? false) && title!.classList!.toggle('active');
  };

  return (
    <div className="flex flex-col items-center">
      <div className="relative flex w-auto mt-[92px] mb-12 lg:mt-[146px] lg:mb-16">
        <img
          className="absolute w-10 -top-9 -left-5 rotate-[15deg] lg:-left-12"
          src="/assets/images/lines/curve.png"
          alt=""
        />
        <span className="text-2xl font-semibold text-center font-title text-secondary-500">
          {t("faq.title1")}&nbsp;
          <br className="lg:hidden" />
          {t("faq.title2")}
        </span>
        <img
          className="absolute -rotate-[65deg] -top-15 -right-12 w-20 -scale-y-100 lg:w-30 lg:-right-[70px] lg:-top-24"
          src="/assets/images/other/dialog_baloon_clear_sharp.png"
          alt=""
        />
        <img
          className="absolute w-10 rotate-180 -right-10 -bottom-6 lg:-right-16 lg:-bottom-8"
          src="/assets/images/lines/curve.png"
          alt=""
        />
      </div>
      <Accordion id="faq" collapseAll>
        <>{faqItems && typeof faqItems === "object" &&
          faqItems
            .sort((a, b) => simpleSortForOrderedObjects(a, b))
            .map((faqItem, i) => {
              console.log(faqItem)
              return <Accordion.Panel id={`faq-item-${i}`} key={`faq-item`} className="relative">
                <Accordion.Title id={`faq-title-${i}`} className="pointer-events-none faq-title">
                  {faqItem.title}
                  <Button
                    pill
                    as="div"
                    id="flip"
                    color="white"
                    className="chevron-button ml-auto min-w-[30px] max-w-[30px] bg-white rounded-full shadow-button pointer-events-auto w-[30px] h-[30px]
                   flex flex-col justify-center cursor-pointer border-[#cacaca] border-solid border"
                    onClick={(e) => expand(e)}
                  >
                    <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 8.66406L17.0623 0.601805" stroke="black" strokeLinecap="round" />
                      <path d="M9.06226 8.66406L0.999998 0.601805" stroke="black" strokeLinecap="round" />
                    </svg>
                  </Button>
                </Accordion.Title>
                <Accordion.Content dangerouslySetInnerHTML={{ __html: sanitize(faqItem.content, { ALLOWED_TAGS: ["a"] }) }}>
                </Accordion.Content>
              </Accordion.Panel>
            }
            )}</>
      </Accordion>
    </div>
  );
};
