import { useState } from "react";
import { AppContext } from "./app-context";

interface Props {
  children: React.ReactNode;
}

export const AppContextProvider: React.FunctionComponent<Props> = (
  props: Props
) => {
  const [fireAnimations, setFireAnimations] = useState(false);



  return (
    <AppContext.Provider value={{ fireAnimations, setFireAnimations }}>
      {props.children}
    </AppContext.Provider>
  );
};
