import './news-card.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Card } from 'flowbite-react';

type NewsCardOptions = {
  newsURL: string;
  imageHeader?: string;
  imageHeaderSize?: string;
  title?: string;
  titleClassName?: string;
  content?: string;
  contentType?: 'video' | 'image';
  poster?: string;
  contentClassName?: string;
};

export const NewsCard = ({
  newsURL = '',
  imageHeader = undefined,
  imageHeaderSize = '',
  title = '',
  titleClassName = '',
  content = undefined,
  contentType = undefined,
  poster = undefined,
  contentClassName = '',
}: NewsCardOptions) => {
  const [playing, setPlaying] = useState(false);
  const videoPlayerRef = useRef<HTMLVideoElement | null>(null);

  const startVideo = useCallback(() => {
    (document.getElementById('video-player') as HTMLVideoElement).play();
  }, []);

  const pauseVideo = useCallback(() => {
    (document.getElementById('video-player') as HTMLVideoElement).pause();
  }, []);

  useEffect(() => {
    if (playing) {
      videoPlayerRef.current?.play();
    } else {
      videoPlayerRef.current?.pause();
    }
  }, [playing, startVideo, pauseVideo]);

  function handleClick(play: boolean, event: any) {
    setPlaying(play);
    event.preventDefault();
  }

  return (
    <Card className="max-w-[325px] card relative cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700">
      <a
        className="flex flex-col p-4 z-0"
        href={newsURL}
        target="_blank"
        rel="noreferrer"
        onClick={(event) => console.log(event)}
      >
        {imageHeader && <img src={imageHeader} alt={title} className={`m-2 mx-auto drop-shadow ${imageHeaderSize}`} />}

        {title && (
          <span
            className={`font-title text-base font-semibold mt-2 mx-4 ${titleClassName}`}
            dangerouslySetInnerHTML={{ __html: title }}
          ></span>
        )}

        {content && contentType === 'image' && (
          <img src={content} alt={title} className={`mt-3 rounded-card ${contentClassName}`} />
        )}

        {content && contentType === 'video' && (
          <div className={`mt-3 relative z-10 ${playing ? 'playing' : ''}`}>
            <img
              src="/assets/images/news/play.png"
              alt="Play Button"
              id="play-btn"
              className={`h-[57px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
              cursor-pointer hover:opacity-75 z-30 ${playing ? 'hidden' : 'block'}`}
              onClick={(event) => handleClick(true, event)}
            />

            <video
              ref={videoPlayerRef}
              onPause={(event) => handleClick(false, event)}
              onClick={(event) => handleClick(!playing, event)}
              poster={poster}
              className={`rounded-card z-20 ${contentClassName}`}
            >
              <source src={content} type="video/mp4" />
            </video>

            <img
              src="/assets/images/news/pause.png"
              alt="Pause Button"
              id="pause-btn"
              className={`hidden h-[57px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
              cursor-pointer hover:opacity-75 z-30}`}
              onClick={(event) => handleClick(false, event)}
            />
          </div>
        )}
      </a>
    </Card>
  );
};
