import { ScheduleMeetingButton } from "../../atoms/schedule-meeting-button/schedule-meeting-button";

export const ScheduleMeeting = () => {  
  return (
    <div className="relative flex w-full my-32">
      <ScheduleMeetingButton className="mx-auto px-8 w-auto h-14 min-w-[320px] z-10" />

      <img
        src="/assets/images/other/schedule_meeting.png"
        alt="Schedule Meeting"
        className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] max-w-[545px]"
      />
    </div>
  );
};
