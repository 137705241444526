import { Backoffice } from '../../organisms/backoffice/backoffice';
import { ContactUs } from '../../organisms/contact-us/contact-us';
import { CustomerMap } from '../../organisms/customer-map/customer-map';
import { Feedback } from '../../organisms/feedback/feedback';
import { HeroMunicipalities } from '../../organisms/hero-municipalities/hero-municipalities';
import { Impact } from '../../organisms/impact/impact';
import { Metrics } from '../../organisms/metrics/metrics';
import { Tools } from '../../organisms/tools/tools';

const Municipalities = () => {
  return (
    <div id="page" className="municipalities">
      <HeroMunicipalities />
      <Metrics className="municipalities" />
      <Impact />
      <br />
      <Tools />
      <Backoffice />
      <br />
      <CustomerMap />
      <br />
      <Feedback />
      <ContactUs className={'municipalities'} />
    </div>
  );
};

export default Municipalities;
