import { Button, Modal } from 'flowbite-react';
import { Tool } from '../../../api';
import React from 'react';
import { sanitize } from 'dompurify';
import { useTranslation } from 'react-i18next';

type ToolModalProps = {
  tool: Tool;
  openModal: boolean;
  modalCloseHandler: React.Dispatch<any>;
};

export const ToolModal = ({ tool, openModal, modalCloseHandler }: ToolModalProps) => {
  const {t} = useTranslation()

  return (
    tool && (
      <Modal size="5xl" dismissible show={openModal} onClose={() => modalCloseHandler(false)}>
        <Modal.Body
          className="relative overflow-y-auto rounded-card scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thumb-gray-300 scrollbar-track-transparent scrollbar-thin"
        >
          <div className="absolute top-0 w-12 h-12 right-6">
            <Button
              pill
              id="flip"
              color="white"
              className="fixed flex flex-col justify-center w-12 h-12 rotate-45 bg-white border-solid rounded-full cursor-pointer shadow-button border-primary-200"
              onClick={() => modalCloseHandler(false)}
            >
              <svg
                className="w-8 h-8 m-auto text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 18"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 1v16M1 9h16"
                />
              </svg>
            </Button>
          </div>
          <div className="flex flex-col w-full h-full py-2">
            <span className="px-8 mb-8 text-3xl font-semibold font-title">{tool?.title ?? 'No title provided'}</span>
            <div>


            <div
              className={
                'flex flex-row px-4 pb-4 overflow-x-auto gap-x-8 ' +
                'scrollbar-thumb-rounded-full scrollbar-track-rounded-full ' +
                'scrollbar-thumb-gray-300 scrollbar-track-transparent scrollbar-thin ' +
                (tool?.images?.length === 1 ? 'justify-center' : '')
              }
            >
              {[tool.banner].concat(tool?.images ?? []).map((el, i) => {
                return <img key={i} className="max-h-[250px] max-w-none rounded-card shadow-card" src={el} alt="Tool" />;
              })}
            </div>
            </div>
            <div className="flex flex-col w-full lg:w-2/3">

            <span className="px-8 mt-8 mb-4 text-2xl font-title">
              {tool?.technicalSheet?.target?.map((el, i) => {
                if (i > 0) {
                  return ', ' + el;
                } else {
                  return el;
                }
              })}
            </span>
            <span className="px-8 text-base">{tool?.description}</span>

            <span className="px-8 mt-4 text-base font-title">{t("tools.modal.objectives")}:</span>
            {tool?.technicalSheet?.objectives?.map((el, i) => {
              return <li key={i} className="pl-2 text-base leading-6 list-disc ml-14">{el}</li>;
            })}

            <span className="px-8 mt-4 text-base font-title">{t("tools.modal.targetAudience")}:</span>
            <span className="px-8 text-base">{tool?.technicalSheet?.targetAudience}</span>

            <span className="px-8 mt-4 text-base font-title">{t("tools.modal.format")}:</span>
            <span className="px-8 text-base">{tool?.technicalSheet?.format}</span>

            <span className="px-8 mt-4 text-base font-title">{t("tools.modal.results")}:</span>
            <span
              className="px-8 text-base"
              dangerouslySetInnerHTML={{ __html: sanitize(tool?.technicalSheet?.results ?? '') }}
            ></span>

            <span className="px-8 mt-4 text-base font-title">{t("tools.modal.customers")}:</span>
            <div className="flex flex-wrap px-8">
              {tool?.technicalSheet?.customers?.map((el, i) => {
                if (i > 0) {
                  return ', ' + el;
                } else {
                  return el;
                }
              })}
            </div>
          </div>
            </div>
        </Modal.Body>
      </Modal>
    )
  );
};
