import { Button } from 'flowbite-react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../contexts/app/app-context';
import './hero.scss';


export const Hero = ({ className }: any) => {
  const { fireAnimations } = useContext(AppContext);

  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  return (
    <div
      className={`hero gap-x-14 flex flex-col-reverse sm:flex-row justify-around w-full pt-10 mb-10 relative ${className ?? ""}`}
    >
      <div className="w-full -mt-20 lg:w-1/2 lg:mt-0">
        <img className="hidden w-4/5 mx-auto lg:block" src="/assets/images/other/loud_speaker.png" alt="Loud Speaker" />

        <img
          className="block lg:hidden w-[235px] ml-auto mr-10"
          src="/assets/images/other/loud_speaker_mobile.png"
          alt="Loud Speaker"
        />
      </div>
      <div className="flex flex-col items-center justify-around w-full px-4 lg:w-1/2 gap-y-10">
        <div
          className="flex flex-col items-start justify-center w-full px-4 text-4xl text-secondary-500 font-title gap-y-2 -rotate-5 lg:text-5xl"
        >
          <span className="px-4">
            {t("landing.hero.creating1")} <span className="px-3 text-white bg-primary-500">{t("landing.hero.creating2")}</span>
          </span>
          <div className="flex items-center px-4 mt-2">
            {t("landing.hero.space1")}
            <div className={`flex image-grow ${fireAnimations ? 'animate' : ''}`}>
              <img className="m-auto rotate-6" src="/assets/images/arrows/double_arrow.svg" alt="Double sided arrow" />
            </div>
            {t("landing.hero.space2")}
          </div>
          <span className="px-4">{t("landing.hero.forA")}</span>
          <span className="px-4 py-2 mt-2 text-white rounded-full w-max bg-danger-500">{t("landing.hero.democracy")}</span>
          <span className="flex flex-row px-4 mt-2">
            {t("landing.hero.forAll1")}&nbsp;
            <span className="flex flex-col gap-y-2 w-fit">
              {t("landing.hero.forAll2")}&nbsp;
              <div className={`-mt-2 draw ${fireAnimations ? 'animate' : ''}`}>
                <img
                  className={`rotate-6 ${lang}`}
                  src="/assets/images/lines/downward_curve.png"
                  alt="Downward curving line"
                />
              </div>
            </span>
          </span>
          <div className="px-4 text-xl text-primary-900/50">
            {t("landing.hero.transform1")}
            <br />
            {t("landing.hero.transform2")}
            <br />
            {t("landing.hero.transform3")}
          </div>
        </div>
        <div className="flex flex-col w-full mt-5 gap-y-6">
          <div className="relative flex flex-row justify-between w-full px-4">
            <Button
              pill
              href="https://community.mypolis.eu/register"
              target="_blank"
              color="primary"
              className="hidden min-w-[180px] h-12 lg:flex"
            >
              <span className="text-xl font-semibold">{t("common.createAcct")}</span>
            </Button>

            <img
              className={`hidden lg:block absolute right-0 max-w-[120px] ${fireAnimations ? 'animate' : ''}`}
              id="arrow-down-twirl"
              src="/assets/images/arrows/arrow_down_twirl.png"
              alt="Arrow down"
            />

            <Button
              pill
              href="https://community.mypolis.eu/login"
              target="_blank"
              color="white"
              className="hidden h-12 min-w-[180px] lg:flex  text-secondary-500"
            >
              <span className="text-xl font-semibold ">{t("common.login")}</span>
            </Button>

            <Button
              pill
              href="https://community.mypolis.eu/login"
              target="_blank"
              color="primary"
              className="flex w-2/5 h-12 mr-auto lg:hidden"
            >
              <span className="text-xl font-semibold">{t("common.login")}</span>
            </Button>

            <img
              className={`block lg:hidden absolute -bottom-16 -left-12  max-w-[135px] rotate-180 ${fireAnimations ? 'animate' : ''}`}
              id="arrow-up-twirl"
              src="/assets/images/arrows/arrow_down_twirl.png"
              alt="Arrow down"
            />
          </div>
          <div className="hidden w-full px-4 lg:hidden">
            <Button pill className="w-full h-12" color="white">
              <img className="w-8 h-auto mr-3" src="/assets/images/logos/google_logo.svg" alt="Google Logo" />
              &nbsp;
              <span className="text-xl font-semibold">{t("common.registerWithGoogle")}</span>
            </Button>
          </div>
        </div>
      </div>

      <img
        className={`hero-star absolute -bottom-48 -left-12 lg:-bottom-56 lg:left-48 max-w-[180px] lg:max-w-[280px] ${fireAnimations ? 'animate' : ''}`}
        src="/assets/images/other/star.png"
        alt="Star"
      />
    </div>
  );
};
