import { sanitize } from 'dompurify';
import { useTranslation } from 'react-i18next';

export const CPP = () => {
  const { t } = useTranslation(['cpp']);
  const cppItems: { title: string; description1: string; description2: string; items: string[] }[] = t('topics', {
    returnObjects: true,
  });

  return (
    <div id="page" className="flex flex-col !mx-7 md:!mx-10 lg:!mx-auto">
      <div className="flex flex-col mt-8">
        <span className="text-xl font-normal">{t('entityName')}</span>
        <span className="text-3xl font-bold mt-2">{t('title')}</span>
      </div>

      {cppItems.map((cppItem) => {
        return (
          <div className="flex flex-col mt-12">
            <span className="text-xl font-bold">{cppItem.title}</span>
            {cppItem.description1 && (
              <span
                className="text-lg font-normal mt-2"
                dangerouslySetInnerHTML={{ __html: sanitize(cppItem.description1) }}
              ></span>
            )}
            {cppItem.description2 && (
              <span
                className="text-lg font-normal mt-4"
                dangerouslySetInnerHTML={{ __html: sanitize(cppItem.description2) }}
              ></span>
            )}
            {cppItem.items && cppItem.items.length > 0 && (
              <ul className="text-lg font-normal mt-2 pl-7 list-disc">
                {cppItem.items.map((innerItem) => (
                  <li dangerouslySetInnerHTML={{ __html: sanitize(innerItem) }}></li>
                ))}
              </ul>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default CPP;
