import { CustomerCarousel } from '../../molecules/customer-caroussel/customer-carousel';
import { CustomerMap } from '../../organisms/customer-map/customer-map';
import { ContactUs } from '../../organisms/contact-us/contact-us';
import { Feedback } from '../../organisms/feedback/feedback';
import { Hero } from '../../organisms/hero/hero';
import { ImplementedProposals } from '../../organisms/implemented-proposals/implemented-proposals';
import { Metrics } from '../../organisms/metrics/metrics';
import { PartnerList } from '../../organisms/partner-list/partner-list';

const LandingPage = () => {
  return (
    <div id="page" className="landing-page">
      <Hero />
      <CustomerCarousel />
      <br />
      <Metrics />
      <br />
      <CustomerMap />
      <br />
      <ImplementedProposals />
      <br />
      <PartnerList />
      <br />
      <Feedback />
      <br />
      <ContactUs className={'landing'} />
    </div>
  );
};

export default LandingPage;
