import { sanitize } from "dompurify";
import { Figure } from "../../atoms/figure/figure";

export const TextFigure = ({
  title,
  text,
  maxLength,
  srcImage,
  description = "",
  reverse = false,
  showStarBullet,
}: {
  title: string;
  text: string;
  maxLength: string;
  srcImage: string;
  description?: string;
  reverse?: boolean;
  showStarBullet?: boolean | undefined;
}) => {
  return (
    <div
      className={
        "flex gap-x-14 pt-10 pb-0 lg:pt-10 lg:pb-10 flex-wrap lg:flex-nowrap" +
        (reverse ? " lg:flex-row-reverse" : "")
      }
    >
      <div className="flex flex-col px-8 mb-4 lg:px-0 lg:mb-0">
        <span className={"text-2xl font-title font-medium " + maxLength}>
          {title}
        </span>
        <span
          className={"text-base " + (title.length > 0 ? "pt-4" : "")}
          dangerouslySetInnerHTML={{ __html: sanitize(text) }}
        ></span>
      </div>

      <Figure srcImage={srcImage} description={description} />
    </div>
  );
};
