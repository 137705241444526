import { useContext } from 'react';
import { ImpactDisplayer } from '../../molecules/impact-displayer/impact-displayer';
import './impact.scss';
import { AppContext } from '../../../contexts/app/app-context';
import { useTranslation } from 'react-i18next';

export const Impact = ({ titleVisible = true }) => {
  const { fireAnimations } = useContext(AppContext);

  const { t } = useTranslation();

  const impactMetrics: {
    highlight: string,
    text: string,
    image: string
  }[] = t("localAuthorities.impact.metrics", { returnObjects: true })

  return (
    <div className="flex flex-col items-center impact mt-36 lg:mt-24">
      {titleVisible && (
        <div className="relative flex">
          <img src="/assets/images/lines/curve.svg" alt="Curve" className="absolute rotate-45 -top-6 -left-6" />
          <span className="my-auto text-2xl font-semibold text-center font-title text-secondary-500">
            {t("localAuthorities.impact.title")}
          </span>

          <img
            id="impact-balloon"
            src="/assets/images/other/yellow_balloon.png"
            alt="Balloon"
            className={`absolute -top-16 right-5 lg:-top-10 lg:-right-32 ${fireAnimations ? 'animate' : ''}`}
          />
        </div>
      )}

      {titleVisible && (
        <span className="px-12 mx-auto my-5 text-base text-center font-body lg:px-0">
          {t("localAuthorities.impact.subTitle")}
        </span>
      )}


      <div className="flex flex-wrap justify-center">
        {impactMetrics && typeof impactMetrics === "object" && impactMetrics.map((metric, i) => <ImpactDisplayer
          key={i}
          imgSrc={metric.image ?? "/assets/images/impact/impact_1.svg"}
          title={metric.highlight}
          description={metric.text}
        ></ImpactDisplayer>)}
      </div>

      <div className="flex flex-col mt-5">
        <span className="text-sm text-center">{t("localAuthorities.impact.explainer")}</span>
      </div>
    </div>
  );
};
