import { useState } from 'react';
import { SlideGesture } from '../gestures/slide-gesture';
import { chunkify } from '../../../utilities';
type ImageGridDisplayProps = {
  items?: Array<{ src: string; alt: string }>;
  className?: string;
};
export const ImageGridDisplay = ({ items = [], className = '' }: ImageGridDisplayProps) => {
  const [slideGestureActive, setSlideGestureActive] = useState(true);

  const touchHandler = () => {
    setSlideGestureActive(false);
  };

  const chunks = chunkify<{ src: string; alt: string }>(items, 3, true);

  return (
    <div className={`relative z-0 ${className}`} onTouchMove={touchHandler}>
      <div className="mx-auto max-w-[310px] w-[310px] h-[300px] bg-white rounded-card px-4 shadow-button lg:max-w-none lg:w-full">
        <div className="relative flex h-full py-5 overflow-x-auto overflow-y-hidden">
          <div className="grid flex-wrap justify-start h-full grid-rows-3 row-auto min-w-fit">
            {chunks &&
              chunks.map((chunk, j) => (
                <div id={`grid-row-${j}`} key={`grid-row-${j}`} className="flex">
                  {chunk.map((item, i) => (
                    <div id={`grid-image-${i}`} key={`grid-image-${i}`} className="flex w-24 px-5">
                      <img className="max-h-full m-auto" src={item.src} alt={item.alt}></img>
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </div>
      </div>
      <SlideGesture active={slideGestureActive} className="absolute w-8 -top-2 h-14 lg:hidden" />
    </div>
  );
};
